import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { STUDY_COLLECTIONS } from '../../../constants/api'
import Search from './Search'
import Table from './Table'
import { CollectionsContainer, ContentContainer, TableContainer } from './StudyCollections.style'

const Collections = () => {
  const { studyCollectionId } = useParams()
  const [studyCollection, setStudyCollection] = useState()

  const [{ data: studyCollectionsResponse }] = useAxios({
    url: STUDY_COLLECTIONS,
  })

  const [{ loading: studyCollectionsResponseIsLoading }, getStudyCollection] = useAxios(
    {
      url: `${STUDY_COLLECTIONS}${studyCollectionId}/`,
    },
    {
      autoCancel: false,
      manual: true,
    },
  )

  const studyCollections = studyCollectionsResponse?.results

  useEffect(() => {
    if (!studyCollectionId) return
    getStudyCollection()?.then((response) => setStudyCollection(response?.data))
  }, [studyCollectionId, getStudyCollection])

  return (
    <CollectionsContainer>
      <ContentContainer>
        <TableContainer>
          <Search studyCollections={studyCollections} setStudyCollection={setStudyCollection} />
          {studyCollectionId && (
            <Table
              studyCollection={studyCollection}
              studyCollectionsResponseIsLoading={studyCollectionsResponseIsLoading}
            />
          )}
        </TableContainer>
      </ContentContainer>
    </CollectionsContainer>
  )
}

export default Collections
