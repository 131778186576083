import { useEffect } from 'react'

import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import Button from '../Button'
import Input from '../Input'

import {
  ContainerBodyWrapper,
  LoginForm,
  LoginFormControl,
  LoginFormControlLabel,
  LoginFormControlItem,
} from './Login.style'

import { useDispatch } from 'react-redux'
import { authActionReducer } from '../../store/authentication/authSlice'

import { useForm } from 'react-hook-form'

import useAxios from 'axios-hooks'

import { AUTHORIZATION } from '../../constants/api'
import { humanize } from '../../utilities'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setUser = (payload) => dispatch(authActionReducer.setUser(payload))

  const [{ data, loading, error }, login] = useAxios(
    {
      method: 'post',
      url: AUTHORIZATION,
    },
    {
      manual: true,
      useCache: false,
    },
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const onSubmit = (data) => {
    login({
      data,
    })
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        trigger().then((rs) => {
          if (rs) {
            const { username, password } = getValues()
            onSubmit({
              username,
              password,
            })
          }
        })
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  useEffect(() => {
    if (data && data.token) {
      setUser(data)
      navigate(`/`)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      const str = humanize(error.response.data, true).join(' ')
      toast.error(str)
    }
  }, [error])

  return (
    <ContainerBodyWrapper>
      <LoginForm onSubmit={handleSubmit(onSubmit)}>
        <h2>iCardio.ai login</h2>

        <LoginFormControl>
          <LoginFormControlLabel>Enter your email</LoginFormControlLabel>
          <LoginFormControlItem>
            <Input
              placeholder="Email"
              name="username"
              {...register('username', {
                required: 'Please enter your email address',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Invalid email address',
                },
              })}
              errors={errors?.username?.message}
              onChange={(e) => {
                setValue('username', e.target.value, { shouldValidate: true })
              }}
            />
          </LoginFormControlItem>
        </LoginFormControl>
        <LoginFormControl>
          <LoginFormControlLabel>Enter your password</LoginFormControlLabel>
          <LoginFormControlItem>
            <Input
              placeholder="Password"
              type="password"
              name="password"
              {...register('password', { required: 'Please enter your password' })}
              errors={errors?.password?.message}
              onChange={(e) => {
                setValue('password', e.target.value, { shouldValidate: true })
              }}
            />
          </LoginFormControlItem>
          <Button
            style={{
              marginTop: '15px',
            }}
            loading={loading}
            fullwidth
            label={'Login'}
            buttonType="default"
            type="submit"
          />
        </LoginFormControl>
      </LoginForm>
    </ContainerBodyWrapper>
  )
}

export default Login
