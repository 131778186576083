import styled from 'styled-components'

export const SpinnerContainer = styled.div.attrs({
  className: 'SpinnerContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 540px;
  width: 100%;
  background: gray;
  border-radius: 0 0 5px 5px;
  // min-width: 300px;
`
