import { API_ICARDIO } from './env.const'

export const ANNOTATION_SETS = `${API_ICARDIO}/api/v2/labeler/annotation-sets/`
export const AUTHORIZATION = `${API_ICARDIO}/api/v2/labeler/authorization/`
export const CARDIAC_CYCLE_PHASES = `${API_ICARDIO}/api/v2/labeler/cardiac-cycle-phases/`
export const DICOM_JOBS = `${API_ICARDIO}/api/v2/labeler/dicom-labeling-jobs/`
export const DICOM_PHASE_LABELS = `${API_ICARDIO}/api/v2/labeler/dicom-phase-labels/`
export const DICOMS = `${API_ICARDIO}/api/v2/labeler/dicoms/`
export const FEATURES = `${API_ICARDIO}/api/v2/labeler/features/`
export const FRAMES = `${API_ICARDIO}/api/v2/labeler/frames/`
export const KEYPOINT_COLLECTIONS = `${API_ICARDIO}/api/v2/labeler/keypoint-collections/`
export const KEYPOINT_COLLECTION_CLASSES = `${API_ICARDIO}/api/v2/labeler/keypoint-collection-classes/`
export const KEYPOINT_JOBS = `${API_ICARDIO}/api/v2/labeler/keypoint-jobs/`
export const PERSPECTIVES = `${API_ICARDIO}/api/v2/labeler/perspectives/`
export const SEGMENTATION_JOBS = `${API_ICARDIO}/api/v2/labeler/segmentation-jobs/`
export const SEGMENTATIONS = `${API_ICARDIO}/api/v2/labeler/segmentations/`
export const STUDY_COLLECTIONS = `${API_ICARDIO}/api/v2/labeler/study-collections/`
export const STUDIES = `${API_ICARDIO}/api/v2/labeler/studies/`
export const VIEWPORTS = `${API_ICARDIO}/api/v2/labeler/viewports/`
