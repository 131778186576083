import styled from 'styled-components'

export const FrameContainer = styled.div.attrs({
  className: 'FrameContainer',
})(
  ({ isActive }) => `
    height: 100%;
    background: black;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 100%;
    display:${isActive ? 'flex' : 'none'};
  `,
)

export const LoaderContainer = styled.div.attrs({
  className: 'LoaderContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  `,
)

export const StyledImage = styled.img.attrs({
  className: 'StyledImage',
})(
  ({ scale, isHidden }) => `
    display: ${isHidden ? 'none' : 'block'};
    transform: scale(${scale});
    transition: transform 0.2s ease-in-out;
    height: 100%;
    // width: 100%:
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `,
)
