import { useCallback, useEffect, useRef, useState } from 'react'
import { Canvas, Path, PencilBrush } from 'fabric'
import { useHotkeys } from 'react-hotkeys-hook'
import { EraserBrush } from 'erase2d'

import { DrawContainer, DrawSection, WrapDrawControl, Button } from './Draw.style'

const Draw = ({ activeSegmentation, setActiveSegmentation, dicom, scale, showMeasurements }) => {
  const fabricRef = useRef(null)
  const [isTurnedEraser, setTurnedEraser] = useState(false)
  const [isTurnedSelect, setTurnedSelect] = useState(false)
  const [history, setHistory] = useState([])
  const [mods, setModes] = useState(0)

  useHotkeys('ctrl', (event) => {
    event?.preventDefault()
  })

  useHotkeys(['del', 'delete', 'backspace'], (event) => {
    event?.preventDefault()

    if (fabricRef.current.getActiveObject()) {
      fabricRef.current.remove(fabricRef.current.getActiveObject())
      updateModifications(true)
    }
  })

  const updateModifications = useCallback(
    (savehistory) => {
      if (savehistory === true) {
        const myjson = JSON.stringify(fabricRef.current)
        const origin = JSON.parse(JSON.stringify(history))
        origin.push(myjson)
        setHistory(origin)
      }
    },
    [history],
  )

  const undo = () => {
    if (mods < history.length) {
      fabricRef.current.clear()
      fabricRef.current.renderAll()
      if (mods > 0) {
        fabricRef.current.loadFromJSON(history[history.length - 1 - mods - 1])
      }
      setTimeout(() => {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.strokeDashArray = []
          o.stroke = '#ff0000c4'
          o.erasable = true
        })
        fabricRef.current.renderAll()
      }, 100)
      setModes((_mods) => (_mods += 1))
    }
  }

  const redo = () => {
    if (mods > 0) {
      fabricRef.current.clear()
      fabricRef.current.renderAll()
      fabricRef.current.loadFromJSON(history[history.length - 1 - mods + 1])
      setTimeout(() => {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.strokeDashArray = []
          o.stroke = '#ff0000c4'
          o.erasable = true
        })
        fabricRef.current.renderAll()
      }, 100)
      setModes((_mods) => (_mods -= 1))
    }
  }

  const handleControlToggle = (type) => {
    fabricRef.current.isDrawingMode = true
    switch (type) {
      case 'eraser':
        setTurnedEraser(true)
        const eraser = new EraserBrush(fabricRef.current)
        eraser.width = 30

        eraser.on('start', (e) => {
          // inform something
        })

        eraser.on('end', (e) => {
          // e.preventDefault()
          eraser.commit(e.detail)
          fabricRef.current.renderAll()
          updateModifications(true)
        })

        fabricRef.current.freeDrawingBrush = eraser
        fabricRef.current.freeDrawingBrush.id = 'eraser'
        break

      default:
        setTurnedEraser(false)
        fabricRef.current.freeDrawingBrush = new PencilBrush(fabricRef.current)
        fabricRef.current.freeDrawingBrush.color = '#03a9f4'
        fabricRef.current.freeDrawingBrush.width = 1
        fabricRef.current.freeDrawingBrush.strokeDashArray = [10]
        fabricRef.current.freeDrawingBrush.fill = '#ff000082'
        fabricRef.current.freeDrawingBrush.id = 'pencil'
        break
    }
  }

  useEffect(() => {
    fabricRef.current = new Canvas('Draw', {
      erasable: true,
      isDrawingMode: true,
    })
    fabricRef.current.counter = 0

    const pathStrings = activeSegmentation?.paths

    const paths = pathStrings?.map(
      (pathString) =>
        new Path(pathString, {
          fill: 'red',
          strokeWidth: 2,
          opacity: 0.5,
        }),
    )

    paths?.forEach((path) => fabricRef.current.add(path))

    fabricRef.current.freeDrawingBrush = new PencilBrush(fabricRef.current)
    fabricRef.current.freeDrawingBrush.color = '#03a9f4'
    fabricRef.current.freeDrawingBrush.width = 1
    fabricRef.current.freeDrawingBrush.strokeDashArray = [10]
    fabricRef.current.freeDrawingBrush.fill = '#ff000082'
    fabricRef.current.freeDrawingBrush.id = 'pencil'
    fabricRef.current.on('object:modified', function () {
      updateModifications(true)
    })
    fabricRef.current.on('mouse:up', function () {
      if (!fabricRef.current.freeDrawingBrush) {
        return
      }
      if (fabricRef.current.freeDrawingBrush.id !== 'eraser') {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.strokeDashArray = []
          o.stroke = '#ff0000c4'
          o.erasable = true
          o.selectable = true
        })
        updateModifications(true)
      }

      const newSegmentation = {
        ...activeSegmentation,
        paths: fabricRef.current.getObjects().map((o) => o.path?.join('')),
        dataUrl: fabricRef.current.toDataURL('image/png'),
      }

      setActiveSegmentation(newSegmentation)
      // }
      // fabricRef.current.renderAll()
    })
    fabricRef.current.on('mouse:moving', function () {
      if (fabricRef.current.freeDrawingBrush.id !== 'eraser') {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.erasable = true
        })
      }
    })

    fabricRef.current.setWidth(dicom?.pixelWidth)
    fabricRef.current.setHeight(dicom?.pixelHeight)

    const disposeFabric = () => {
      fabricRef.current.dispose()
    }

    return () => {
      disposeFabric()
    }
  }, [dicom?.pixelWidth, dicom?.pixelHeight, activeSegmentation, updateModifications, setActiveSegmentation])

  return (
    <DrawContainer scale={scale} tabIndex="1000">
      <WrapDrawControl>
        <Button
          active={isTurnedSelect}
          onClick={() => {
            if (!isTurnedSelect) {
              setTurnedEraser(false)
              setTurnedSelect(true)
              fabricRef.current.isDrawingMode = false
              fabricRef.current.freeDrawingBrush = false
            } else {
              setTurnedSelect(false)
              handleControlToggle(null)
            }
          }}
        >
          Select
        </Button>
        <Button
          active={isTurnedEraser}
          onClick={() => {
            setTurnedSelect(false)
            if (!isTurnedEraser) {
              handleControlToggle('eraser')
            } else {
              handleControlToggle(null)
            }
          }}
        >
          Eraser
        </Button>
        <Button
          onClick={() => {
            undo()
          }}
        >
          Undo {mods}
        </Button>
        <Button
          onClick={() => {
            redo()
          }}
        >
          Redo
        </Button>
      </WrapDrawControl>
      <DrawSection />
    </DrawContainer>
  )
}

export default Draw
