import { memo, useEffect, useMemo } from 'react'

import FeatureOption from './FeatureOption'
import PhaseOption from './PhaseOption'
import { LabelsContainer, FeatureContainer, FeatureOptionsContainer, FeatureTitle } from './Labels.style'

import cardiacPhases from './cardiacPhases.json'

const Labels = ({
  activeFrame,
  systolicFrame,
  diastolicFrame,
  features,
  activeFeature,
  setSystolicFrame,
  setDiastolicFrame,
  setActiveFeature,
}) => {
  const phaseOptions = useMemo(
    () =>
      cardiacPhases?.map((phase, index) => {
        const hotkey = phase?.[0]
        const setPhase = phase === 'Systolic' ? setSystolicFrame : setDiastolicFrame
        const isSelected =
          phase === 'Systolic'
            ? activeFrame?.index === systolicFrame?.index
            : activeFrame?.index === diastolicFrame?.index
        return <PhaseOption key={index} hotkey={hotkey} label={phase} setPhase={setPhase} isSelected={isSelected} />
      }),
    [activeFrame?.index, diastolicFrame?.index, setDiastolicFrame, setSystolicFrame, systolicFrame?.index],
  )

  const featureOptions = useMemo(
    () =>
      features?.map((feature, index) => {
        const isActive = activeFeature === feature?.uuid
        const setActive = () => setActiveFeature(isActive ? null : feature?.uuid)
        const segmentation = activeFrame?.segmentations?.find(
          (segmentation) => segmentation?.feature?.uuid === feature?.uuid,
        )
        const isLabeled = !!segmentation?.paths
        return (
          <FeatureOption
            key={feature?.uuid}
            feature={feature?.value}
            hotkey={`${index + 1}`}
            isActive={isActive}
            isLabeled={isLabeled}
            setActive={setActive}
          />
        )
      }),
    [activeFeature, activeFrame?.segmentations, features, setActiveFeature],
  )

  useEffect(() => setActiveFeature(features?.[0]?.uuid), [activeFrame?.index, features, setActiveFeature])

  return (
    <LabelsContainer>
      {/* <TitleContainer>Labels</TitleContainer> */}
      <FeatureContainer>
        <FeatureTitle>Phase</FeatureTitle>
        <FeatureOptionsContainer>{phaseOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      <FeatureContainer>
        <FeatureTitle>Features</FeatureTitle>
        <FeatureOptionsContainer>{featureOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
