import styled from 'styled-components'

export const DrawContainer = styled.div.attrs({
  className: 'DrawContainer',
  id: 'FramesWrapper',
})(
  ({ scale }) => `
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 100%;
    // height: 100%;
    cursor: pointer;
    transform: scale(${scale});
    transition: transform 0.2s ease-in-out;
  `,
)

export const DrawSection = styled.canvas.attrs({
  className: 'DrawSection',
  id: 'Draw',
})`
  width: 100% !important;
  height: 100% !important;
`
