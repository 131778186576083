import useAxios from 'axios-hooks'

import Loader from './Loader'
import { ANNOTATION_SETS } from '../../constants/api'
import { useSelector } from 'react-redux'
import { Card, CardsContainer, ContentContainer, HomeContainer, RowContainer } from './Home.style'
import SummaryCard from './SummaryCard'

const Home = () => {
  const user = useSelector((state) => state.auth.user)
  const userIsLoggedIn = !!user

  const [{ data, loading }] = useAxios(
    {
      url: ANNOTATION_SETS,
    },
    {
      useCache: false,
    },
  )

  const loadingCards = Array.from({ length: 3 }).map((_, index) => (
    <Card key={index}>
      <Loader />
    </Card>
  ))

  const annotationSetCards = data?.results?.map((annotationSet) => (
    <SummaryCard annotationSet={annotationSet} key={annotationSet?.uuid} />
  ))

  return (
    <HomeContainer>
      <ContentContainer>
        {userIsLoggedIn ? (
          <RowContainer>
            <div>My Open Jobs</div>
            <CardsContainer>{loading ? loadingCards : annotationSetCards}</CardsContainer>
          </RowContainer>
        ) : (
          <span>Please login</span>
        )}
      </ContentContainer>
    </HomeContainer>
  )
}

export default Home
