import styled from 'styled-components'

export const LoadingWrapper = styled.div.attrs({
  className: 'LoadingWrapper',
})`
  color: #f1f4f6;
  display: inline-block;
  width: 20px;
  height: 20px;
  animation: 1.4s linear 0s infinite normal none running rotating;
  svg circle {
    stroke: currentcolor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
  }
`
