import styled from 'styled-components'

import THEME from '../../../constants/theme'

export const ActionsContainer = styled.div.attrs({
  className: 'ActionsContainer',
})`
  background: gray;
  border-radius: 5px;
`

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#222'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${disabled ? 'none' : '#444'};;
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const CanvasContainer = styled.div.attrs({
  className: 'CanvasContainer',
})(
  ({ isHidden }) => `  
    display: ${isHidden ? 'none' : 'flex'};
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 10px;
    > div { 
      flex-grow: 1;
    }
  `,
)

export const PageContainer = styled.div.attrs({
  className: 'PageContainer',
})`
  height: calc(100% - 60px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  height: calc(100% - 90px);
  display: flex;
  wdith: 100%;
  gap: 10px;
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  width: 100%;
  background: #ccc;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})(
  ({ isHidden, size }) => `
    height: 100%;
    width: 100%;
    display: ${isHidden ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;
    // background: gray;
    gap: 10px;
    border-radius: 5px;
    > div {
      // flex-grow: 1;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    > svg {
      width: ${size}px;
      height: ${size}px;
      stroke: ${isHidden ? 'none' : '#666'} !important;       
    }
  `,
)

export const LabelsContainer = styled.div.attrs({
  className: 'LabelsContainer',
})`
  display: flex;
  gap: 5px;
`

export const LabelTag = styled.div.attrs({
  className: 'LabelTag',
})(
  ({ type }) => `  
    background: ${
      type === 'identifier'
        ? THEME.identifier
        : type === 'phaser'
          ? THEME.variant6
          : type === 'keypointer'
            ? THEME.keypoints
            : type === 'segmentor'
              ? THEME.segmentations
              : THEME.variant8
    };
    color: white;
    border-radius: 5px;
    height: 40px;
    width: ${type === 'identifier' ? 137 : 100}px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 12px;
    padding: 5px;
  `,
)

export const RightSideBar = styled.div.attrs({
  className: 'RightSideBar',
})(
  ({ isHidden, isOpen }) => `  
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: ${isOpen ? 300 : 100}px;
    min-width: ${isOpen ? 300 : 100}px;
    flex-direction: column;
    // overflow-y: auto;
    // background: gray;
    gap: 10px;
    border-radius: 5px;
    transition: 0.25s;
    position: relative;
  `,
)

export const SideBarContainer = styled.div.attrs({
  className: 'SideBarContainer',
})(
  ({ isHidden, isOpen }) => `
    height: 100%;
    width: 100%;
    display: ${isHidden ? 'none' : 'flex'};
    flex-direction: column;
    overflow-y: auto;
    background: gray;
    gap: 10px;
    border-radius: 5px;
    > div {
      // flex-grow: 1;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `,
)

export const SmallLoaderContainer = styled.div.attrs({
  className: 'SmallLoaderContainer',
})(
  ({ isHidden }) => `  
    height: 40px;
    width: 40px;
    margin-right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)

export const StatusTag = styled.div.attrs({
  className: 'StatusTag',
})(
  ({ isHidden, isLabeled, isSkipped }) => `  
    background: ${isLabeled ? 'green' : isSkipped ? 'red' : 'none'};
    color: ${isLabeled || isSkipped ? 'white' : 'none'};
    border-radius: 5px;
    height: 40px;
    width: 100px;
    padding: 0 10px;
    display: ${isHidden ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
  `,
)

export const Title = styled.div.attrs({
  className: 'Title',
})`
  font-size: 1.5em;
  width: 100%;
  text-align: center;
`

export const ViewerContainer = styled.div.attrs({
  className: 'ViewerContainer',
})`
  background: black;
  height: 100%;
  width: 100%;
`
