import React from 'react'
import { InputForm, InputWrapper, ErrorMessage } from './Input.style'

const Input = ({ errors, ...props }, ref) => {
  return (
    <>
      <InputWrapper>
        <InputForm {...props} ref={ref} />
      </InputWrapper>
      {errors && <ErrorMessage>{errors}</ErrorMessage>}
    </>
  )
}

export default React.forwardRef(Input)
