import styled from 'styled-components'

export const ControlsContainer = styled.div.attrs({
  className: 'ControlsContainer',
})`
  background: gray;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 5px;
  padding: 0px 10px;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`

export const ToggleContainer = styled.div.attrs({
  className: 'ToggleContainer',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #aaa;
  }
`

export const ToggleSectionContainer = styled.div.attrs({
  className: 'ToggleSectionContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
`
