import { memo, useMemo } from 'react'

import FeatureOption from './FeatureOption'
import {
  LabelsContainer,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  Checkbox,
  OptionContainer,
} from './Labels.style'
import PhaseOption from './PhaseOption'

const Labels = ({
  features,
  activeFeature,
  activeFrame,
  dicom,
  dicomPhases,
  segmentations,
  setActiveFrameIndex,
  setActiveFeature,
  setSegmentations,
}) => {
  const usablePhases = dicomPhases?.filter((dicomPhase) => dicomPhase?.phase !== 'Mid-Systole')
  const phaseLabels = usablePhases?.map((dicomPhase, index) => {
    const isActive = activeFrame?.uuid === dicomPhase?.frame
    const featureValues = features?.map((feature) => feature?.value)
    const labeledSegmentations = segmentations?.filter(
      (segmentation) =>
        segmentation?.frame === dicomPhase?.frame &&
        featureValues.includes(segmentation?.feature?.value) &&
        (!!segmentation?.paths?.length || !segmentation?.isFullyVisible),
    )
    const isLabeled = labeledSegmentations?.length === features?.length
    const handlePhaseOptionOnClick = () => setActiveFrameIndex(dicomPhase?.frameIndex)

    return (
      <PhaseOption
        key={index}
        dicomPhase={dicomPhase}
        isActive={isActive}
        isLabeled={isLabeled}
        handlePhaseOptionOnClick={handlePhaseOptionOnClick}
      />
    )
  })

  const featureOptions = useMemo(
    () =>
      features?.map((feature, index) => {
        const segmentation = segmentations?.find(
          (segmentation) => segmentation?.feature?.uuid === feature?.uuid && segmentation?.frame === activeFrame?.uuid,
        )
        const isActive = activeFeature?.uuid === feature?.uuid
        const isLabeled = !!segmentation?.paths?.length || segmentation?.isFullyVisible === false

        const setActive = () => setActiveFeature(feature)
        return (
          <FeatureOption
            key={feature?.uuid}
            feature={feature?.value}
            hotkey={`${index + 1}`}
            isActive={isActive}
            isLabeled={isLabeled}
            setActive={setActive}
          />
        )
      }),
    [activeFeature?.uuid, activeFrame?.uuid, features, segmentations, setActiveFeature],
  )

  const visibilityHotkeys = ['J', 'K', 'N', 'M']
  const visibilityOptions = features?.map((feature, index) => {
    const segmentation = segmentations?.find(
      (segmentation) => segmentation?.feature?.uuid === feature?.uuid && segmentation?.frame === activeFrame?.uuid,
    )
    const isFullyVisible = segmentation ? segmentation?.isFullyVisible : true
    const handleVisibilityOptionOnClick = () => {
      const nonActiveSegmentations = segmentations?.filter(
        (segmentation) => !(segmentation?.feature?.uuid === feature?.uuid && segmentation?.frame === activeFrame?.uuid),
      )
      setSegmentations([
        ...nonActiveSegmentations,
        {
          ...segmentation,
          dicom: dicom?.uuid,
          feature,
          frame: activeFrame?.uuid,
          frameIndex: activeFrame?.index,
          isFullyVisible: !isFullyVisible,
        },
      ])
    }
    return (
      <OptionContainer key={index} onClick={handleVisibilityOptionOnClick}>
        <Checkbox checked={isFullyVisible} readOnly />({visibilityHotkeys[index]}) {feature?.value} is Fully Visible
      </OptionContainer>
    )
  })

  return (
    <LabelsContainer>
      <FeatureContainer>
        <FeatureTitle>Phase</FeatureTitle>
        <FeatureOptionsContainer>{phaseLabels}</FeatureOptionsContainer>
      </FeatureContainer>
      <FeatureContainer>
        <FeatureTitle>Features</FeatureTitle>
        <FeatureOptionsContainer>{featureOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      <FeatureContainer>
        <FeatureTitle>Visibility</FeatureTitle>
        <FeatureOptionsContainer>{visibilityOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
