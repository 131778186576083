import { useRef, useState } from 'react'

import useOutsideClick from '../../../../hooks/outsideClick'
import { useNavigate } from 'react-router-dom'
import {
  AnnotationSetOption,
  AnnotationSetOptionsContainer,
  InputContainer,
  SearchContainer,
  SearchInput,
} from './Search.style'

const Search = ({ annotationSets, setAnnotationSet }) => {
  const navigate = useNavigate()

  const containerRef = useRef()

  const [dropdownIsOpen, setDropDownIsOpen] = useState(false)

  const handleInputOnChange = () => {
    /* filter annotationSets */
  }

  const handleInputOnFocus = () => setDropDownIsOpen(true)

  const handleOptionOnClick = (annotationSet) => {
    setDropDownIsOpen(false)
    setAnnotationSet(annotationSet)
    navigate(`/studies/${annotationSet?.uuid}`)
  }

  const annotationSetsOptions = annotationSets?.map((annotationSet) => (
    <AnnotationSetOption key={annotationSet?.uuid} onClick={() => handleOptionOnClick(annotationSet)}>
      {annotationSet?.name}
    </AnnotationSetOption>
  ))

  useOutsideClick(
    containerRef,
    () => {
      if (!dropdownIsOpen) return
      setDropDownIsOpen(false)
    },
    [dropdownIsOpen],
  )

  return (
    <SearchContainer ref={containerRef}>
      <InputContainer>
        <SearchInput placeholder="search datasets" onChange={handleInputOnChange} onFocus={handleInputOnFocus} />
        <AnnotationSetOptionsContainer isOpen={dropdownIsOpen}>{annotationSetsOptions}</AnnotationSetOptionsContainer>
      </InputContainer>
    </SearchContainer>
  )
}

export default Search
