import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'

import { FactContainer, JobChartContainer, Percent } from './JobChart.style'

// Register the necessary components
Chart.register(ArcElement, Tooltip, Legend)

const JobChart = ({ keypointJobs, selectedUsers }) => {
  const selectedJobs = keypointJobs?.filter((job) => selectedUsers?.includes(job.user))
  const labeledJobs = selectedJobs?.filter((job) => job.labeled)
  const skippedJobs = selectedJobs?.filter((job) => job.skipped)
  const remainingJobs = selectedJobs?.filter((job) => !job.labeled && !job.skipped)

  const labeledPercent = ((labeledJobs?.length / selectedJobs?.length) * 100).toFixed(1)
  const skippedPercent = ((skippedJobs?.length / selectedJobs?.length) * 100).toFixed(1)
  const remainingPercent = ((remainingJobs?.length / selectedJobs?.length) * 100).toFixed(1)

  const data = [
    { label: 'Labeled', value: labeledJobs?.length },
    { label: 'Skipped', value: skippedJobs?.length },
    { label: 'Remaining', value: remainingJobs?.length },
  ]

  const dataConfig = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: ['rgba(0, 255, 0, 0.5)', 'rgba(255, 0, 0, 0.5)', 'rgba(0, 0, 255, 0.5)'],
        borderColor: ['rgba(0, 255, 0, 1)', 'rgba(255, 0, 0, 1)', 'rgba(0, 0, 255, 1)'],
        borderWidth: 1,
      },
    ],
  }

  return (
    <JobChartContainer>
      <div>
        <FactContainer>
          <div>Labeled</div>
          <div>
            {labeledJobs?.length} <Percent>({labeledPercent}%)</Percent>
          </div>
        </FactContainer>
        <FactContainer>
          <div>Skipped</div>
          <div>
            {skippedJobs?.length} <Percent>({skippedPercent}%)</Percent>
          </div>
        </FactContainer>
        <FactContainer>
          <div>Remaining</div>
          <div>
            {remainingJobs?.length} <Percent>({remainingPercent}%)</Percent>
          </div>
        </FactContainer>
        <FactContainer>
          <div>Total</div>
          <div>{selectedJobs?.length}</div>
        </FactContainer>
      </div>
      <Pie data={dataConfig} />
    </JobChartContainer>
  )
}

export default JobChart
