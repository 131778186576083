import { Oval } from 'react-loader-spinner'

import { SpinnerContainer } from './Loader.syle'

const Loader = () => (
  <SpinnerContainer>
    <Oval
      visible={true}
      height="80"
      width="80"
      color="#222"
      secondaryColor="#aaa"
      ariaLabel="oval-loading"
      wrapperClass="SpinnerContainer"
    />
  </SpinnerContainer>
)

export default Loader
