import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { ANNOTATION_SETS } from '../../../constants/api'
import Search from './Search'
import Table from './Table'
import { AnnotationSetsContainer, ContentContainer, TableContainer } from './AnnotationSets.style'

const AnnotationSets = () => {
  const { annotationSetId } = useParams()
  const [annotationSet, setAnnotationSet] = useState()

  const [{ data: annotationSetsResponse }] = useAxios({
    url: ANNOTATION_SETS,
    params: { type: 'FULL_STUDY' },
  })

  const [{ loading: annotationSetsResponseIsLoading }, getAnnotationSet] = useAxios(
    {
      url: `${ANNOTATION_SETS}${annotationSetId}/`,
    },
    {
      autoCancel: false,
      manual: true,
    },
  )

  const annotationSets = annotationSetsResponse?.results

  useEffect(() => {
    if (!annotationSetId) return
    getAnnotationSet()?.then((response) => setAnnotationSet(response?.data))
  }, [annotationSetId, getAnnotationSet])

  return (
    <AnnotationSetsContainer>
      <ContentContainer>
        <TableContainer>
          <Search annotationSets={annotationSets} setAnnotationSet={setAnnotationSet} />
          {annotationSetId && (
            <Table annotationSet={annotationSet} annotationSetsResponseIsLoading={annotationSetsResponseIsLoading} />
          )}
        </TableContainer>
      </ContentContainer>
    </AnnotationSetsContainer>
  )
}

export default AnnotationSets
