import styled from 'styled-components'
import THEME from '../../../constants/theme'

export const CardContent = styled.div.attrs({
  className: 'CardContent',
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CardFact = styled.div.attrs({
  className: 'CardFact',
})`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 5px;
`

export const CardTitle = styled.div.attrs({
  className: 'CardTitle',
})`
  font-size: 18px;
  font-weight: bold;
  background: #444;
  padding: 10px;
  border-radius: 5px 5px 0 0;
`

export const SummaryCardContainer = styled.a.attrs({
  className: 'SummaryCardContainer',
})`
  all: unset;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  background-color: gray;
  width: 300px;
  // height: 210px;
  cursor: pointer;
  align-items: space-between;
  gap: 10px;
  padding-bottom: 10px;
  &:hover {
    opacity: 0.9;
  }
`

export const TypeTag = styled.div.attrs({
  className: 'TypeTag',
})(
  ({ type }) => `
    background: ${type === 'KEYPOINTS' ? THEME.keypoints : type === 'SEGMENTATION' ? THEME.segmentations : THEME.bloodRed};
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 12px;
    width: fit-content;
  `,
)
