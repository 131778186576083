import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  points: null,
  datasets: null,
  dicomResponse: null,
  keypointJobs: null,
}

export const dicomSlice = createSlice({
  name: 'dicom',
  initialState,
  reducers: {
    setPoints: (state, action) => {
      state.points = action.payload
    },
    setDataset: (state, action) => {
      state.datasets = action.payload
    },
    setDicomResponse: (state, action) => {
      state.dicomResponse = action.payload
    },
    setKeypointJobs: (state, action) => {
      state.keypointJobs = action.payload
    },
  },
})

export const dicomActionReducer = dicomSlice.actions
export default dicomSlice.reducer
