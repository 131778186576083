import { useHotkeys } from 'react-hotkeys-hook'

import { Checkbox, FeatureOptionContainer } from './FeatureOption.style'

const FeatureOption = ({ feature, hotkey, isActive, isLabeled, setActive }) => {
  const label = feature

  const handleFeatureOptionOnClick = () => {
    setActive()
  }

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    setActive()
  })

  return (
    <FeatureOptionContainer isLabeled={isLabeled} isActive={isActive} onClick={handleFeatureOptionOnClick}>
      <Checkbox checked={isLabeled} readOnly />({hotkey}) {label}
    </FeatureOptionContainer>
  )
}

export default FeatureOption
