// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for all scrollable elements */
::-webkit-scrollbar {
  width: 10px; /* Vertical scrollbar width */
  height: 10px; /* Horizontal scrollbar height */
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the scrollbar thumb */
  border: 2px solid #f9f9f9; /* Optional border for the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* Color of the scrollbar track */
  box-shadow: inset 0 0 5px grey; /* Optional: Inner shadow for the track */
  border-radius: 0 5px 5px 0;
}

::-webkit-scrollbar-button {
  display: none; /* Hide scrollbar buttons */
}
`, "",{"version":3,"sources":["webpack://./src/components/Collections/Auditor/Dicoms/Dicoms.style.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;EACE,WAAW,EAAE,6BAA6B;EAC1C,YAAY,EAAE,gCAAgC;EAC9C,aAAa;AACf;;AAEA;EACE,yBAAyB,EAAE,iCAAiC;EAC5D,kBAAkB,EAAE,4CAA4C;EAChE,yBAAyB,EAAE,kCAAkC;AAC/D;;AAEA;EACE,yBAAyB,EAAE,iCAAiC;EAC5D,8BAA8B,EAAE,yCAAyC;EACzE,0BAA0B;AAC5B;;AAEA;EACE,aAAa,EAAE,2BAA2B;AAC5C","sourcesContent":["/* Styles for all scrollable elements */\n::-webkit-scrollbar {\n  width: 10px; /* Vertical scrollbar width */\n  height: 10px; /* Horizontal scrollbar height */\n  display: none;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #c1c1c1; /* Color of the scrollbar thumb */\n  border-radius: 5px; /* Rounded corners for the scrollbar thumb */\n  border: 2px solid #f9f9f9; /* Optional border for the thumb */\n}\n\n::-webkit-scrollbar-track {\n  background-color: #f9f9f9; /* Color of the scrollbar track */\n  box-shadow: inset 0 0 5px grey; /* Optional: Inner shadow for the track */\n  border-radius: 0 5px 5px 0;\n}\n\n::-webkit-scrollbar-button {\n  display: none; /* Hide scrollbar buttons */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
