import { FramePreviewContainer, ScrollerContainer } from './Scroller.style'

const Scroller = ({ dicom, activeFrame, pauseVideo, setActiveFrame, systolicFrame, diastolicFrame }) => {
  const systolicFrameIndex = systolicFrame?.index
  const diaFrameIndex = diastolicFrame?.index

  const framePreviews = dicom?.frames
    ?.sort((a, b) => a?.index - b?.index)
    ?.map((frame) => {
      const handleFrameOnClick = () => {
        setActiveFrame(frame?.index)
        pauseVideo()
      }
      const isActive = activeFrame === frame?.index
      const isSystolic = systolicFrameIndex === frame?.index
      const isDiastolic = diaFrameIndex === frame?.index
      return (
        <FramePreviewContainer
          key={frame?.uuid}
          isActive={isActive}
          isSystolic={isSystolic}
          isDiastolic={isDiastolic}
          onClick={handleFrameOnClick}
        >
          {frame?.index + 1}
        </FramePreviewContainer>
      )
    })

  return <ScrollerContainer>{framePreviews}</ScrollerContainer>
}

export default Scroller
