import styled from 'styled-components'

export const InputWrapper = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  cursor: text;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.23);
`

export const InputForm = styled.input.attrs({
  className: 'InputForm',
})`
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: calc(100% - 28px);
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  color: #fff;
`

export const ErrorMessage = styled.span.attrs({
  className: 'ErrorMessage',
})`
  color: #a70e0e;
  padding-top: 5px;
  font-size: 14px;
`
