import styled from 'styled-components'

export const BodyContainer = styled.div.attrs({
  className: 'BodyContainer',
})`
  width: 100vw;
  height: calc(100vh - 60px);
  margin-top: 60px;
  position: relative;
  background: black;
`
