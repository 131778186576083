import { useCallback, useEffect, useMemo, useState } from 'react'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { ANNOTATION_SETS, SEGMENTATION_JOBS, SEGMENTATIONS } from '../../../constants/api'
import Actions from './Actions'
import Canvas from './Canvas'
import Controls from './Controls'
import Details from './Details'
import Labels from './Labels'
import Loader from './Loader'
import {
  Button,
  CanvasContainer,
  ContentContainer,
  HeaderContainer,
  SideBarContainer,
  StatusTag,
  ViewerContainer,
} from './Viewer.style'

const Viewer = () => {
  const { datasetId, jobId } = useParams()

  const datasetUrl = useMemo(() => `${ANNOTATION_SETS}${datasetId}/`, [datasetId])
  const keyPointUrl = useMemo(() => `${SEGMENTATION_JOBS}${jobId}/`, [jobId])

  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [{ data: datasetResponse, loading: datasetResponseIsLoading }] = useAxios(datasetUrl, {
    useCache: false,
  })
  const [{ data: segmentationJobResponse, loading: segmentationJobResponseIsLoading }] = useAxios(keyPointUrl, {
    useCache: false,
  })

  const [dataset, setDataset] = useState()
  const [segmentationJob, setSegmentationJob] = useState()
  const [activeFrameIndex, setActiveFrameIndex] = useState(0)
  const [frames, setFrames] = useState([])
  const [segmentations, setSegmentations] = useState([])
  const [videoState, setVideoState] = useState(false)
  const [intervalId, setIntervalId] = useState()
  const [activeFeature, setActiveFeature] = useState(null)

  const [nextOnSave, setNextOnSave] = useState(true)
  const [showMeasurements, setShowMeasurements] = useState(true)

  const dicom = segmentationJob?.dicom
  const numberOfSegmentationJobs = dataset?.numberOfJobs
  const numberOfFrames = dicom?.frames?.length
  const numberOfSeconds = 1000 / dicom?.framesPerSecond
  const features = dataset?.features

  const segmentationsParams = useMemo(
    () => ({
      frame__media__dicom__uuid: segmentationJob?.dicom?.uuid,
      features__uuid__in: features?.map((feature) => feature?.uuid).join(','),
      paginate: false,
    }),
    [features, segmentationJob?.dicom?.uuid],
  )

  const [{ loading: segmentationsResponseisLoading }, getSegmentations] = useAxios(
    {
      url: SEGMENTATIONS,
      params: segmentationsParams,
    },
    {
      manual: true,
      useCache: false,
      autoCancel: false,
    },
  )
  const [{ loading: segmentationsUpsertIsLoading }, upsertSegmentations] = useAxios(SEGMENTATIONS, {
    manual: true,
    autoCancel: false,
  })
  const [{ loading: segmentationJobUpdateIsLoading }, updateSegmentationJob] = useAxios(SEGMENTATION_JOBS, {
    manual: true,
    autoCancel: false,
  })

  const activeFrame = useMemo(() => frames?.[activeFrameIndex], [frames, activeFrameIndex])
  const systolicFrame = useMemo(() => frames?.find((frame) => frame.isSystolic) || {}, [frames])
  const diastolicFrame = useMemo(() => frames?.find((frame) => frame.isDiastolic) || {}, [frames])
  const activeSegmentations = useMemo(() => activeFrame?.segmentations, [activeFrame])
  const activeSegmentation = useMemo(
    () => activeSegmentations?.find((segmentation) => segmentation?.feature?.uuid === activeFeature),
    [activeFeature, activeSegmentations],
  )

  const setActiveSegmentation = useCallback(
    (activeSegmentation) => {
      setFrames((frames) => {
        const segmentations = frames[activeFrameIndex]?.segmentations?.map((segmentation) =>
          segmentation?.feature?.uuid === activeFeature ? activeSegmentation : segmentation,
        )

        return [
          ...frames?.slice(0, activeFrameIndex),
          { ...frames[activeFrameIndex], segmentations },
          ...frames?.slice(activeFrameIndex + 1),
        ]
      })
    },
    [activeFeature, activeFrameIndex],
  )

  const setSystolicFrame = useCallback(
    (isSelected) => {
      setFrames((frames) => {
        const newFrames = frames?.map((frame) => ({ ...frame, isSystolic: false }))
        return [
          ...newFrames.slice(0, activeFrameIndex),
          { ...newFrames[activeFrameIndex], isSystolic: isSelected, isDiastolic: false },
          ...newFrames.slice(activeFrameIndex + 1),
        ]
      })
    },
    [activeFrameIndex],
  )

  const setDiastolicFrame = useCallback(
    (isSelected) => {
      setFrames((frames) => {
        const newFrames = frames?.map((frame) => ({ ...frame, isDiastolic: false }))
        return [
          ...newFrames.slice(0, activeFrameIndex),
          { ...newFrames[activeFrameIndex], isDiastolic: isSelected, isSystolic: false },
          ...newFrames.slice(activeFrameIndex + 1),
        ]
      })
    },
    [activeFrameIndex],
  )

  const playVideo = useCallback(
    (speed) => {
      setVideoState(speed)
      const fps = numberOfSeconds * speed
      const id = setInterval(() => {
        setActiveFrameIndex((activeFrameIndex) => {
          if (activeFrameIndex === numberOfFrames - 1) return 0
          return activeFrameIndex + 1
        })
      }, fps)
      setIntervalId(id)
    },
    [numberOfFrames, numberOfSeconds],
  )

  const pauseVideo = useCallback(() => {
    if (intervalId !== null) {
      setVideoState(0)
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [intervalId])

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [intervalId])

  useEffect(() => {
    if (!frames?.length) return
    getSegmentations().then((response) => setSegmentations(response?.data))
  }, [frames?.length, getSegmentations])

  useEffect(() => {
    if (!datasetResponse || !segmentationJobResponse) return
    setDataset(datasetResponse)
    setSegmentationJob(segmentationJobResponse)
    setActiveFeature(datasetResponse?.features?.[0]?.uuid)
  }, [datasetResponse, segmentationJobResponse])

  useEffect(() => {
    const framesData = dicom?.frames?.map((frame) => {
      const labeledSegmentations = features?.map((feature) => {
        const segmentation = segmentations?.find(
          (segmentation) => segmentation.frame === frame.uuid && segmentation.feature?.uuid === feature.uuid,
        )
        return {
          ...segmentation,
          frame: frame?.uuid,
          feature: feature,
        }
      })

      // TODO: fix how to determine if frame is systolic or diastolic
      const isSystolic = labeledSegmentations?.[0]?.cardiacCyclePhase === 'Systolic'
      const isDiastolic = labeledSegmentations?.[0]?.cardiacCyclePhase === 'Diastolic'

      return {
        ...frame,
        isSystolic,
        isDiastolic,
        segmentations: labeledSegmentations,
      }
    })

    setFrames(framesData)
  }, [dataset, dicom, features, segmentations])

  // wait 1 second for all components to mount
  useEffect(() => {
    // Function to check if any API is currently loading
    const checkIfApisAreLoading = () =>
      datasetResponseIsLoading ||
      segmentationJobResponseIsLoading ||
      segmentationsResponseisLoading ||
      segmentationsUpsertIsLoading ||
      segmentationJobUpdateIsLoading

    // Initial check if APIs are loading
    if (checkIfApisAreLoading()) {
      setDataIsLoading(true)

      // Promise that resolves when all API calls are completed
      const apisLoaded = new Promise((resolve) => {
        const checkApis = () => {
          if (!checkIfApisAreLoading()) {
            resolve()
          } else {
            setTimeout(checkApis, 1000) // Check every 1 second
          }
        }
        checkApis()
      })

      // Promise that resolves after at least 1 second
      const oneSecondWait = new Promise((resolve) => {
        setTimeout(resolve, 1000)
      })

      // Use Promise.all to wait for both promises to resolve
      Promise.all([apisLoaded, oneSecondWait]).then(() => {
        setDataIsLoading(false)
      })
    } else {
      // If APIs are not loading, wait for at least 1 second before setting loading to false
      setTimeout(() => setDataIsLoading(false), 1000)
    }
  }, [
    datasetResponseIsLoading,
    segmentationJobResponseIsLoading,
    segmentationJobUpdateIsLoading,
    segmentationsResponseisLoading,
    segmentationsUpsertIsLoading,
    setDataIsLoading,
  ])

  return (
    <ViewerContainer>
      <HeaderContainer>
        <Button>
          <a href={`/segmentations/${datasetId}`}>
            <IoMdArrowRoundBack />
            <span>BACK TO DATASET</span>
          </a>
        </Button>
        <StatusTag isHidden={dataIsLoading} isLabeled={segmentationJob?.labeled} isSkipped={segmentationJob?.skipped}>
          {segmentationJob?.labeled ? 'LABELED' : segmentationJob?.skipped ? 'SKIPPED' : ''}
        </StatusTag>
      </HeaderContainer>
      <ContentContainer>
        <SideBarContainer isHidden={!dataIsLoading}>
          <Loader />
        </SideBarContainer>
        <SideBarContainer isHidden={dataIsLoading}>
          <Details
            dataset={dataset}
            systolicFrame={systolicFrame}
            diastolicFrame={diastolicFrame}
            isLoading={dataIsLoading}
          />
          <Labels
            activeFrame={activeFrame}
            systolicFrame={systolicFrame}
            diastolicFrame={diastolicFrame}
            activeSegmentations={activeSegmentations}
            activeFeature={activeFeature}
            features={features}
            setSystolicFrame={setSystolicFrame}
            setDiastolicFrame={setDiastolicFrame}
            setSegmentations={setSegmentations}
            setActiveFeature={setActiveFeature}
          />
          <Controls
            nextOnSave={nextOnSave}
            setNextOnSave={setNextOnSave}
            showMeasurements={showMeasurements}
            setShowMeasurements={setShowMeasurements}
          />
          <Actions
            dataIsLoading={dataIsLoading}
            dataset={dataset}
            diastolicFrame={diastolicFrame}
            frames={frames}
            segmentationJob={segmentationJob}
            nextOnSave={nextOnSave}
            systolicFrame={systolicFrame}
            setActiveFrameIndex={setActiveFrameIndex}
            setSegmentations={setSegmentations}
            setSegmentationJob={setSegmentationJob}
            updateSegmentationJob={updateSegmentationJob}
            upsertSegmentations={upsertSegmentations}
            pauseVideo={pauseVideo}
          />
        </SideBarContainer>
        <CanvasContainer isHidden={!dataIsLoading}>
          <Loader />
        </CanvasContainer>
        <CanvasContainer isHidden={dataIsLoading}>
          <Canvas
            segmentationJob={segmentationJob}
            numberOfSegmentationJobs={numberOfSegmentationJobs}
            dicom={dicom}
            datasetId={datasetId}
            frames={frames}
            playVideo={playVideo}
            pauseVideo={pauseVideo}
            videoState={videoState}
            activeFrame={activeFrame}
            activeSegmentations={activeSegmentations}
            systolicFrame={systolicFrame}
            diastolicFrame={diastolicFrame}
            setFrames={setFrames}
            setActiveFrame={setActiveFrameIndex}
            setSegmentations={setSegmentations}
            showMeasurements={showMeasurements}
            activeFeature={activeFeature}
            activeSegmentation={activeSegmentation}
            setActiveSegmentation={setActiveSegmentation}
          />
        </CanvasContainer>
      </ContentContainer>
    </ViewerContainer>
  )
}

export default Viewer
