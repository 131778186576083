import { useHotkeys } from 'react-hotkeys-hook'

import { Checkbox, KeypointOptionContainer } from './KeypointOption.style'

const KeypointOption = ({ keypointClass, keypoint, setKeypointIsActive }) => {
  const hotkey = `${keypointClass?.order}`
  const isLabeled = keypoint?.isLabeled
  const isActive = keypoint?.isActive
  const label = keypointClass?.value

  const handleKeypointOptionOnClick = () => {
    setKeypointIsActive(!isActive)
  }

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    setKeypointIsActive(!isActive)
  })

  return (
    <KeypointOptionContainer isLabeled={isLabeled} isActive={isActive} onClick={handleKeypointOptionOnClick}>
      <Checkbox checked={isLabeled} readOnly />({hotkey}) {label}
    </KeypointOptionContainer>
  )
}

export default KeypointOption
