import { FramePreviewContainer, ScrollerContainer } from './Scroller.style'

const Scroller = ({ frames, activeFrame, pauseVideo, setActiveFrame, dicomPhases }) => {
  const systolicFrameIndex = dicomPhases?.find((dicomPhase) => dicomPhase?.phase === 'Systolic')?.frameIndex
  const diastolicFrameIndex = dicomPhases?.find((dicomPhase) => dicomPhase?.phase === 'Diastolic')?.frameIndex
  const midSystoleFrameIndex = dicomPhases?.find((dicomPhase) => dicomPhase?.phase === 'Mid-Systole')?.frameIndex

  const framePreviews = frames
    ?.sort((a, b) => a?.index - b?.index)
    ?.map((frame) => {
      const handleFrameOnClick = () => {
        setActiveFrame(frame?.index)
        pauseVideo()
      }
      const isActive = activeFrame === frame?.index
      const isSystolic = systolicFrameIndex === frame?.index
      const isDiastolic = diastolicFrameIndex === frame?.index
      const ismidSystole = midSystoleFrameIndex === frame?.index

      const renderedIndex = frame?.index + 1
      const lineOne = renderedIndex < 10 ? '0' : renderedIndex.toString()[0]
      const lineTwo = renderedIndex < 10 ? renderedIndex.toString() : renderedIndex.toString()[1]

      return (
        <FramePreviewContainer
          key={frame?.uuid}
          isActive={isActive}
          isSystolic={isSystolic}
          isDiastolic={isDiastolic}
          ismidSystole={ismidSystole}
          onClick={handleFrameOnClick}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>{lineOne}</div>
            <div>{lineTwo}</div>
          </div>
        </FramePreviewContainer>
      )
    })

  return <ScrollerContainer>{framePreviews}</ScrollerContainer>
}

export default Scroller
