import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'

import {
  Button,
  ButtonsContainer,
  IdentifierContainer,
  QualityButton,
  Title,
  ViewsContainer,
  WindowsContainer,
} from './Identifier.style'
import { TitleContainer } from '../Actions/Actions.style'

const Identifier = ({ dicom, viewports, perspective, setPerspective }) => {
  const type = dicom?.type
  const uniqueWindows = [...new Set(viewports?.map((viewport) => viewport?.window))]

  const windowOrders = ['Long Axis', 'Short Axis', 'Apical', 'Subcostal', 'Suprasternal', 'Miscellaneous']
  const orderedWindows = uniqueWindows.sort((a, b) => {
    const stringA = a.replace(' Color', '')
    const stringB = b.replace(' Color', '')
    const indexA = windowOrders.indexOf(stringA)
    const indexB = windowOrders.indexOf(stringB)

    // Handle elements not found in referenceList
    if (indexA === -1) return 1
    if (indexB === -1) return -1

    return indexA - indexB
  })

  const goodQualityIsActive = perspective?.quality === 'good'
  const badQualityIsActive = perspective?.quality === 'bad'

  const handleQualityOnClick = (type) => {
    setPerspective({
      ...perspective,
      dicom: dicom?.uuid,
      quality: type,
    })
  }

  const buttons = orderedWindows?.map((window) => {
    const filteredViewports = viewports
      ?.filter((viewport) => viewport?.type === type)
      ?.filter((viewport) => viewport?.window === window)
      ?.map((viewport, index) => {
        const handleViewportOnClick = () =>
          setPerspective({
            ...perspective,
            dicom: dicom?.uuid,
            viewport: viewport?.uuid,
          })

        return (
          <Button key={index} onClick={handleViewportOnClick} isActive={perspective?.viewport === viewport?.uuid}>
            {viewport.view}
          </Button>
        )
      })

    return (
      <ViewsContainer key={window}>
        <Title>{window}</Title>
        <ButtonsContainer>{filteredViewports}</ButtonsContainer>
      </ViewsContainer>
    )
  })

  return (
    <IdentifierContainer>
      <TitleContainer>Select View</TitleContainer>
      <WindowsContainer>{buttons}</WindowsContainer>
    </IdentifierContainer>
  )
}

export default Identifier
