import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'

import { FactContainer, JobChartContainer, Percent, Tag } from './JobChart.style'
import THEME from '../../../../constants/theme'

// Register the necessary components
Chart.register(ArcElement, Tooltip, Legend)

const JobChart = ({ annotationSet }) => {
  const selectedJobs =
    annotationSet?.type === 'FULL_STUDY' ? annotationSet?.numberOfStudies : annotationSet?.numberOfJobs
  const labeledJobs =
    annotationSet?.type === 'FULL_STUDY' ? annotationSet?.numberOfLabeledStudies : annotationSet?.numberOfLabeledJobs
  const skippedJobs = annotationSet?.type === 'FULL_STUDY' ? 0 : annotationSet?.numberOfSkippedJobs
  const remainingJobs = selectedJobs - labeledJobs - skippedJobs

  const labeledPercent = ((labeledJobs / selectedJobs) * 100).toFixed(1)
  const skippedPercent = ((skippedJobs / selectedJobs) * 100).toFixed(1)
  const remainingPercent = ((remainingJobs / selectedJobs) * 100).toFixed(1)

  const data = [
    { label: 'Labeled', value: labeledJobs },
    { label: 'Skipped', value: skippedJobs },
    { label: 'Remaining', value: remainingJobs },
  ]

  const labeledBackgroundColor = annotationSet?.type === 'KEYPOINTS' ? THEME.keypoints : THEME.segmentations
  const skippedBackgroundColor = THEME.identifier
  const remainingBackgroundColor = THEME.variant6Light
  const colors = [labeledBackgroundColor, skippedBackgroundColor, remainingBackgroundColor]

  const dataConfig = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        color: 'white',
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <JobChartContainer>
      <div>
        {!!labeledJobs && (
          <FactContainer>
            <div>
              <Tag backgroundColor={labeledBackgroundColor}></Tag>
              <div>Labeled</div>
            </div>
            <div>
              {labeledJobs} <Percent>({labeledPercent}%)</Percent>
            </div>
          </FactContainer>
        )}
        {!!skippedJobs && (
          <FactContainer>
            <div>
              <Tag backgroundColor={skippedBackgroundColor}></Tag>
              <div>Skipped</div>
            </div>
            <div>
              {skippedJobs} <Percent>({skippedPercent}%)</Percent>
            </div>
          </FactContainer>
        )}
        <FactContainer>
          <div>
            <Tag backgroundColor={remainingBackgroundColor}></Tag>
            <div>Remaining</div>
          </div>
          <div>
            {remainingJobs} <Percent>({remainingPercent}%)</Percent>
          </div>
        </FactContainer>
        <FactContainer>
          <div>
            <Tag></Tag>
            <div>Total</div>
          </div>
          <div>
            {selectedJobs}
            <Percent></Percent>
          </div>
        </FactContainer>
      </div>
      <br></br>
      <Pie data={dataConfig} options={options} />
    </JobChartContainer>
  )
}

export default JobChart
