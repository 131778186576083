import { useHotkeys } from 'react-hotkeys-hook'

import { Checkbox, PhaseOptionContainer } from './PhaseOption.style'

const PhaseOption = ({ activeFrame, cardiacPhase, dicom, dicomPhase, setDicomPhase }) => {
  const isSelected = ![null, undefined].includes(dicomPhase?.frame)
  const hotkey = cardiacPhase?.value?.[0]
  const label = cardiacPhase?.value

  const handlePhaseOptionOnClick = () => {
    const newPhase =
      dicomPhase?.phase === cardiacPhase?.value && dicomPhase?.frame === activeFrame?.uuid ? null : cardiacPhase?.value

    setDicomPhase({
      ...dicomPhase,
      frame: activeFrame?.uuid,
      frameIndex: activeFrame?.index,
      phase: newPhase,
      dicom: dicom?.uuid,
    })
  }

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    handlePhaseOptionOnClick()
  })

  return (
    <PhaseOptionContainer isSelected={isSelected} onClick={handlePhaseOptionOnClick}>
      <Checkbox checked={isSelected} readOnly />({hotkey}) {label}
    </PhaseOptionContainer>
  )
}

export default PhaseOption
