import styled from 'styled-components'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#222'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${disabled ? 'none' : '#444'};;
    }
  
  `,
)

export const CanvasContainer = styled.div.attrs({
  className: 'CanvasContainer',
})(
  ({ isHidden }) => `  
    // padding: 10px;
    display: ${isHidden ? 'none' : 'flex'};
    gap: 1em;
    flex-direction: column;
    justify-content: space-between;
    background: gray;
    border-radius: 5px;
    // width: calc(100% - 300px);
    height: 100%;
    overflow: hidden;
    position: relative;
  `,
)

export const DicomLink = styled.a.attrs({
  className: 'DicomLink',
})`
  all: unset;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 1em;
  position: relative;
  max-height: calc(100% - 125px - 2em);
  overflow: hidden;
  background: black;
  margin: 10px;
`

export const CurrentFrameContainer = styled.div.attrs({
  className: 'CurrentFrameContainer',
})`
  display: inline-block;
  width: 2ch;
  text-align: right;
  font-family: monospace;
  margin: 0 1ch;
`

export const FooterContainer = styled.div.attrs({
  className: 'FooterContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 1em;
  z-index: 1;
  background: gray;
  padding: 10px;
`

export const FramesWrapper = styled.div.attrs({
  className: 'FramesWrapper',
})`
  display: flex;
  flex-direction: row;
  // width: 100%;
  // height: 100%;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`
