import Table from './Table'
import { DicomsContainer } from './Dicoms.style'
import './Dicoms.style.css'

const Dicoms = ({ dicoms }) => {
  return (
    <DicomsContainer>
      <Table dicoms={dicoms} />
    </DicomsContainer>
  )
}

export default Dicoms
