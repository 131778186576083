import { useHotkeys } from 'react-hotkeys-hook'

import { Checkbox, KeypointCollectionOptionContainer } from './KeypointCollectionOption.style'

const KeypointCollectionOption = ({
  keypointCollectionClass,
  setActiveKeypointCollectionClass,
  hotkey,
  isActive,
  isLabeled,
}) => {
  const label = keypointCollectionClass?.value

  const handleKeypointCollectionOptionOnClick = () => {
    setActiveKeypointCollectionClass(keypointCollectionClass)
  }

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    setActiveKeypointCollectionClass(keypointCollectionClass)
  })

  return (
    <KeypointCollectionOptionContainer
      isLabeled={isLabeled}
      isActive={isActive}
      onClick={handleKeypointCollectionOptionOnClick}
    >
      <Checkbox checked={isLabeled} readOnly />({hotkey}) {label}
    </KeypointCollectionOptionContainer>
  )
}

export default KeypointCollectionOption
