import icardioAiLogo from '../assets/images/logo.svg'
import logoutIcon from '../assets/images/icons/logout.svg'
import linkedInIcon from '../assets/images/icons/linkedin.svg'
import crossHairIcon from '../assets/images/icons/crosshair_icon.png'


export const ICARDIO_AI_LOGO = icardioAiLogo
export const LOGOUT_ICON = logoutIcon
export const LINKEDIN_ICON = linkedInIcon
export const CROSSHAIR_ICON = crossHairIcon
