import styled from 'styled-components'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#222'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    // width: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${disabled ? 'none' : '#444'};;
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const AnnotationSetsContainer = styled.div.attrs({
  className: 'AnnotationSetsContainer',
})`
  height: 100%;
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  height: calc(100% - 60px);
  padding: 15px;
  display: flex;
  gap: 15px;
`

export const TableContainer = styled.div.attrs({
  className: 'TableContainer',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
`
