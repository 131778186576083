import { useHotkeys } from 'react-hotkeys-hook'

import { Checkbox, PhaseOptionContainer } from './PhaseOption.style'

const PhaseOption = ({ dicomPhase, isActive, isLabeled, handlePhaseOptionOnClick }) => {
  const hotkey = dicomPhase?.phase?.[0]
  const label = dicomPhase?.phase

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    handlePhaseOptionOnClick()
  })

  return (
    <PhaseOptionContainer isSelected={isActive} onClick={handlePhaseOptionOnClick}>
      <Checkbox checked={isLabeled} readOnly />({hotkey}) {label}
    </PhaseOptionContainer>
  )
}

export default PhaseOption
