import { useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { SEGMENTATION_JOBS, SEGMENTATIONS } from '../../../../constants/api'
import { Button, ButtonsContainer, ActionsContainer } from './Actions.style'
import { useNavigate } from 'react-router-dom'

const Actions = ({
  dataIsLoading,
  dataset,
  diastolicFrame,
  frames,
  segmentationJob,
  nextOnSave,
  setActiveFrameIndex,
  systolicFrame,
  setSegmentations,
  setSegmentationJob,
  updateSegmentationJob,
  upsertSegmentations,
  pauseVideo,
}) => {
  const navigate = useNavigate()

  const segmentationsPayload = useMemo(
    () =>
      frames
        ?.filter((frame) => !!frame?.segmentations?.filter((segmentation) => !!segmentation?.paths?.length)?.length)
        ?.map((frame) => {
          const segmentations = frame?.segmentations
            ?.filter((segmentation) => !!segmentation?.paths?.length)
            ?.map((segmentation) => {
              const cardiacCyclePhase = frame?.isDiastolic ? 'Diastolic' : frame?.isSystolic ? 'Systolic' : null
              const frameUuid = frame?.uuid
              const paths = segmentation?.paths
              const dataUrl = segmentation?.dataUrl
              const feature = segmentation?.feature?.uuid

              return {
                cardiacCyclePhase,
                frameUuid,
                feature,
                paths,
                dataUrl,
              }
            })
          return segmentations
        })
        ?.flat(),
    [frames],
  )

  const segmentationJobPayload = useMemo(() => {
    const featuresCount = dataset?.features?.length
    const systolicSegmentationsCount =
      systolicFrame?.segmentations?.filter((segmentation) => !!segmentation?.paths?.length)?.length || 0
    const diastolicSegmentationsCount =
      diastolicFrame?.segmentations?.filter((segmentation) => !!segmentation?.paths?.length)?.length || 0
    const systolicFrameIsLabeled = systolicSegmentationsCount === featuresCount
    const diastolicFrameIsLabeled = diastolicSegmentationsCount === featuresCount
    const labeled = systolicFrameIsLabeled && diastolicFrameIsLabeled
    const skipped = labeled ? false : segmentationJob?.skipped
    return {
      labeled,
      skipped,
    }
  }, [dataset?.features?.length, diastolicFrame?.segmentations, segmentationJob?.skipped, systolicFrame?.segmentations])

  const buttonIsDisabled = dataIsLoading
  const goToNextDicom = nextOnSave && segmentationJob?.next

  const handleSaveOnClick = () => {
    // if ((!systolicFrame || !diastolicFrame) && !window.confirm('Phases not labeled, continue?')) return

    // const systolicKeypoints = systolicFrame?.keypoints?.filter((keypoint) => keypoint?.isLabeled)
    // const diastolicKeypoints = diastolicFrame?.keypoints?.filter((keypoint) => keypoint?.isLabeled)

    // if (
    //   (systolicKeypoints?.length < 5 || diastolicKeypoints?.lenfth < 5) &&
    //   !window.confirm('Missing some systolic and diastolic keypoints, continue?')
    // )
    //   return

    upsertSegmentations({
      url: SEGMENTATIONS,
      data: segmentationsPayload,
      method: 'POST',
    })?.then((response) => {
      if (!goToNextDicom) setSegmentations(response?.data)
    })
    updateSegmentationJob({
      url: `${SEGMENTATION_JOBS}${segmentationJob?.uuid}/`,
      data: segmentationJobPayload,
      method: 'PATCH',
    })?.then((response) => {
      if (!goToNextDicom) setSegmentationJob(response?.data)
    })
    if (goToNextDicom) {
      pauseVideo()
      setActiveFrameIndex(0)
      navigate(`/segmentations/${dataset?.uuid}/${segmentationJob?.next}`)
    }
  }

  const handleSkipOnClick = () => {
    updateSegmentationJob({
      url: `${SEGMENTATION_JOBS}${segmentationJob?.uuid}/`,
      data: {
        labeled: false,
        skipped: true,
      },
      method: 'PATCH',
    })?.then(() => {
      if (!segmentationJob?.next) return
      pauseVideo()
      setActiveFrameIndex(0)
      navigate(`/segmentations/${dataset?.uuid}/${segmentationJob.next}`)
    })
  }

  useHotkeys('p', (event) => {
    event?.preventDefault()
    handleSaveOnClick()
  })

  useHotkeys('L', (event) => {
    event?.preventDefault()
    handleSkipOnClick()
  })

  return (
    <ActionsContainer>
      {/* <TitleContainer>Actions</TitleContainer> */}
      <ButtonsContainer>
        <Button disabled={buttonIsDisabled} onClick={handleSaveOnClick}>
          (P) Save
        </Button>
        <Button disabled={buttonIsDisabled} onClick={handleSkipOnClick}>
          (L) Skip
        </Button>
      </ButtonsContainer>
    </ActionsContainer>
  )
}

export default Actions
