import { useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { KEYPOINT_COLLECTIONS, KEYPOINT_JOBS } from '../../../../constants/api'
import { Button, ButtonsContainer, ActionsContainer, TitleContainer } from './Actions.style'
import { useNavigate } from 'react-router-dom'

const Actions = ({
  dataIsLoading,
  dataset,
  diastolicFrame,
  frames,
  keypointJob,
  nextOnSave,
  setActiveFrameIndex,
  systolicFrame,
  setKeypointCollections,
  setKeypointJob,
  updateKeypointJob,
  upsertKeypointCollections,
  pauseVideo,
}) => {
  const navigate = useNavigate()

  const keypointCollectionsPayload = useMemo(
    () =>
      frames
        ?.filter((frame) => frame?.keypoints?.some((keypoint) => keypoint?.isLabeled))
        ?.map((frame) => {
          const cardiacCyclePhase = frame?.isDiastolic ? 'Diastolic' : frame?.isSystolic ? 'Systolic' : null
          const frameUuid = frame?.uuid
          const keyPointCollectionClass = dataset?.keyPointCollectionClass?.uuid
          const keyPoints = frame?.keypoints?.map((keypoint) => ({
            keyPointClass: keypoint?.keypointClass?.uuid,
            x: keypoint?.x,
            y: keypoint?.y,
          }))

          return {
            cardiacCyclePhase,
            frame: frameUuid,
            keyPointCollectionClass,
            keyPoints,
          }
        }),
    [dataset?.keyPointCollectionClass?.uuid, frames],
  )
  const keypointJobPayload = useMemo(() => {
    const labeledSystolicKeypoints = systolicFrame?.keypoints?.filter((keypoint) => keypoint?.isLabeled)
    const labeledDiastolicKeypoints = diastolicFrame?.keypoints?.filter((keypoint) => keypoint?.isLabeled)
    const labeled =
      labeledSystolicKeypoints?.length === dataset?.keyPointCollectionClass?.keyPointClasses?.length &&
      labeledDiastolicKeypoints?.length === dataset?.keyPointCollectionClass?.keyPointClasses?.length
    const skipped = labeled ? false : keypointJob?.skipped
    return {
      labeled,
      skipped,
    }
  }, [
    dataset?.keyPointCollectionClass?.keyPointClasses?.length,
    diastolicFrame?.keypoints,
    keypointJob?.skipped,
    systolicFrame?.keypoints,
  ])

  const buttonIsDisabled = dataIsLoading
  const goToNextDicom = nextOnSave && keypointJob?.next

  const handleSaveOnClick = () => {
    if ((!systolicFrame || !diastolicFrame) && !window.confirm('Phases not labeled, continue?')) return

    const systolicKeypoints = systolicFrame?.keypoints?.filter((keypoint) => keypoint?.isLabeled)
    const diastolicKeypoints = diastolicFrame?.keypoints?.filter((keypoint) => keypoint?.isLabeled)

    if (
      (systolicKeypoints?.length < 5 || diastolicKeypoints?.lenfth < 5) &&
      !window.confirm('Missing some systolic and diastolic keypoints, continue?')
    )
      return

    upsertKeypointCollections({
      url: KEYPOINT_COLLECTIONS,
      data: keypointCollectionsPayload,
      method: 'POST',
    })?.then((response) => {
      if (!goToNextDicom) setKeypointCollections(response?.data)
    })
    updateKeypointJob({
      url: `${KEYPOINT_JOBS}${keypointJob?.uuid}/`,
      data: keypointJobPayload,
      method: 'PATCH',
    })?.then((response) => {
      if (!goToNextDicom) setKeypointJob(response?.data)
    })
    if (goToNextDicom) {
      pauseVideo()
      setActiveFrameIndex(0)
      navigate(`/keypoints/${dataset?.uuid}/${keypointJob?.next}`)
    }
  }

  const handleSkipOnClick = () => {
    updateKeypointJob({
      url: `${KEYPOINT_JOBS}${keypointJob?.uuid}/`,
      data: {
        labeled: false,
        skipped: true,
      },
      method: 'PATCH',
    })?.then(() => {
      if (!keypointJob?.next) return
      pauseVideo()
      setActiveFrameIndex(0)
      navigate(`/keypoints/${dataset?.uuid}/${keypointJob.next}`)
    })
  }

  useHotkeys('p', (event) => {
    event?.preventDefault()
    handleSaveOnClick()
  })

  useHotkeys('L', (event) => {
    event?.preventDefault()
    handleSkipOnClick()
  })

  return (
    <ActionsContainer>
      {/* <TitleContainer>Actions</TitleContainer> */}
      <ButtonsContainer>
        <Button disabled={buttonIsDisabled} onClick={handleSaveOnClick}>
          (P) Save
        </Button>
        <Button disabled={buttonIsDisabled} onClick={handleSkipOnClick}>
          (L) Skip
        </Button>
      </ButtonsContainer>
    </ActionsContainer>
  )
}

export default Actions
