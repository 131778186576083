import { useEffect, useMemo, useState } from 'react'

import { useHotkeys } from 'react-hotkeys-hook'
import Frame from './Frame'
import Scroller from './Scroller'
import Controls from './Controls'
import { useNavigate } from 'react-router-dom'
import Draw from './Draw'
import { API_ICARDIO } from '../../../../constants/env.const'
import {
  Button,
  CanvasContainer,
  ContentContainer,
  DicomLink,
  FooterContainer,
  FramesWrapper,
  TitleContainer,
} from './Canvas.style'

const Canvas = ({
  keypointJob,
  numberOfKeypoints,
  dicom,
  datasetId,
  frames,
  playVideo,
  pauseVideo,
  videoState,
  activeFrame,
  activeKeypoints,
  systolicFrame,
  diastolicFrame,
  setFrames,
  setActiveFrame,
  setKeypoints,
  showMeasurements,
}) => {
  const navigate = useNavigate()

  const [scale, setScale] = useState(1)
  const [inFrame, setInFrame] = useState(false)

  const numberOfFrames = dicom?.frames?.length

  const canvasFrames = useMemo(
    () =>
      frames
        ?.sort((a, b) => a?.index - b?.index)
        ?.map((frame) => {
          const isActive = frame?.index === activeFrame?.index
          const setFrame = (newFrame) =>
            setFrames((frames) => [...frames?.slice(0, frame?.index), newFrame, ...frames?.slice(frame?.index + 1)])
          return (
            <Frame
              key={frame?.uuid}
              dicom={dicom}
              frame={frame}
              isActive={isActive}
              scale={scale}
              setFrame={setFrame}
            />
          )
        }),
    [activeFrame?.index, dicom, frames, scale, setFrames],
  )

  const dicomLink = (
    <DicomLink href={`${API_ICARDIO}/almanac?dicom=${keypointJob?.dicom?.uuid}`} target="_blank">
      {keypointJob?.dicom?.uuid}
    </DicomLink>
  )

  useEffect(() => {
    const handleScroll = (event) => {
      if (!inFrame) return

      event?.preventDefault()
      event?.stopPropagation()

      const direction = event.deltaY
      // Adjust these values based on how fast you want the zoom to happen
      const zoomIntensity = 0.1
      let newScale = scale

      if (direction < 0) {
        // Scroll up, zoom in
        newScale += zoomIntensity
        if (newScale > 2) newScale = 2 // Prevent zooming in beyond 10x (arbitrary value, adjust as needed
      } else {
        // Scroll down, zoom out
        newScale -= zoomIntensity
        if (newScale < 1) newScale = 1 // Prevent zooming out beyond the original size
      }

      setScale(newScale)
    }

    window.addEventListener('wheel', handleScroll, { passive: false })

    return () => {
      window.removeEventListener('wheel', handleScroll)
    }
  }, [scale, inFrame])

  const handleNext = () => {
    if (!keypointJob?.next) return
    pauseVideo()
    setActiveFrame(0)
    navigate(`/keypoints/${datasetId}/${keypointJob.next}`)
  }

  const handlePrev = () => {
    if (!keypointJob?.previous) return
    pauseVideo()
    setActiveFrame(0)
    navigate(`/keypoints/${datasetId}/${keypointJob.previous}`)
  }

  useHotkeys('e', (event) => {
    event?.preventDefault()
    handleNext()
  })

  useHotkeys('q', (event) => {
    event?.preventDefault()
    handlePrev()
  })

  return (
    <CanvasContainer>
      <TitleContainer>
        <Button disabled={!keypointJob?.previous} onClick={handlePrev}>
          (Q) PREVIOUS
        </Button>
        <div>
          {keypointJob?.order}/{numberOfKeypoints} - {keypointJob?.uuid} - {dicomLink} - {activeFrame?.uuid}
        </div>
        <Button disabled={!keypointJob?.next} onClick={handleNext}>
          (E) NEXT
        </Button>
      </TitleContainer>
      <ContentContainer onMouseEnter={() => setInFrame(true)} onMouseLeave={() => setInFrame(false)}>
        <FramesWrapper>{canvasFrames}</FramesWrapper>
        <Draw
          activeFrame={activeFrame?.index}
          activeKeypoints={activeKeypoints}
          dicom={dicom}
          scale={scale}
          setKeypoints={setKeypoints}
          showMeasurements={showMeasurements}
        />
      </ContentContainer>
      <FooterContainer>
        <Scroller
          pauseVideo={pauseVideo}
          dicom={dicom}
          activeFrame={activeFrame?.index}
          setActiveFrame={setActiveFrame}
          systolicFrame={systolicFrame}
          diastolicFrame={diastolicFrame}
        />
        <Controls
          playVideo={playVideo}
          pauseVideo={pauseVideo}
          videoState={videoState}
          setActiveFrame={setActiveFrame}
          numberOfFrames={numberOfFrames}
        />
      </FooterContainer>
    </CanvasContainer>
  )
}

export default Canvas
