import { useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { DatasetOption, DatasetOptionsContainer, InputContainer, SearchContainer, SearchInput } from './Search.style'

const Search = ({ datasets, setDataset }) => {
  const navigate = useNavigate()

  const containerRef = useRef()

  const [dropdownIsOpen, setDropDownIsOpen] = useState(false)

  const handleInputOnChange = () => {
    /* filter datasets */
  }

  const handleInputOnFocus = () => setDropDownIsOpen(true)

  const handleOptionOnClick = (dataset) => {
    setDropDownIsOpen(false)
    setDataset(dataset)
    navigate(`/segmentations/${dataset?.uuid}`)
  }

  const datasetsOptions = datasets?.map((dataset) => (
    <DatasetOption key={dataset?.uuid} onClick={() => handleOptionOnClick(dataset)}>
      {dataset?.name}
    </DatasetOption>
  ))

  return (
    <SearchContainer ref={containerRef}>
      <InputContainer>
        <SearchInput placeholder="search annotation sets" onChange={handleInputOnChange} onFocus={handleInputOnFocus} />
        <DatasetOptionsContainer isOpen={dropdownIsOpen}>{datasetsOptions}</DatasetOptionsContainer>
      </InputContainer>
    </SearchContainer>
  )
}

export default Search
