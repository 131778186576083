import styled from 'styled-components'
import THEME from '../../../../constants/theme'

export const Box = styled.div.attrs({
  className: 'Box',
})(
  ({ type }) => `  
    background: ${
      type === 'identifier'
        ? THEME.identifier
        : type === 'phaser'
          ? THEME.variant6
          : type === 'keypointer'
            ? THEME.keypoints
            : type === 'segmentor'
              ? THEME.segmentations
              : 'green'
    };
    padding: 2x;
    height: 13px;
    width: 13px;
    border: 1px solid black;
  `,
)

export const BoxContainer = styled.div.attrs({
  className: 'BoxContainer',
})`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const BoxesContainer = styled.div.attrs({
  className: 'BoxesContainer',
})`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const DescriptionContainer = styled.div.attrs({
  className: 'DescriptionContainer',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 13px 0;
`

export const DicomCardsContainer = styled.div.attrs({
  className: 'DicomCardsContainer',
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  gap: 10px;
`

export const DicomCardContainer = styled.div.attrs({
  className: 'DicomCardContainer',
})(
  ({ isActive, isLabeled }) => `
    width: 100%;
    > a {
      all: unset;
      padding: 0 15px 0 5px;
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      // width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      cursor: pointer;
      background: ${isLabeled ? '#5A5C51' : '#666'};
      border: ${isActive ? '1px solid black' : isLabeled ? '1px solid #5A5C51' : '1px solid #666'};
      &:hover {
        opacity: 0.9;
      }
    }
  `,
)

export const DicomThumbnail = styled.img.attrs({
  className: 'DicomThumbnail',
})`
  height: 50px;
  width: 75px;
  border-radius: 5px;
`

export const DicomVideoThumbnail = styled.video.attrs({
  className: 'DicomVideoThumbnail',
})`
  height: 50px;
  width: 75px;
  border-radius: 5px;
`

export const EmptyBox = styled.div.attrs({
  className: 'EmptyBox',
})`
  content: '-';
  padding: 2x;
  height: 13px;
  width: 13px;
  text-align: center;
`
