import { memo, useMemo } from 'react'

import KeypointOption from './KeypointOption'
import {
  LabelsContainer,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  OptionContainer,
  Checkbox,
} from './Labels.style'

import KeypointCollectionOption from './KeypointCollectionOption'
import PhaseOption from './PhaseOption'

const Labels = ({
  activeFrame,
  activeFrameIndex,
  dicomPhases,
  setActiveFrameIndex,
  activeKeypoints,
  setKeypoints,
  keypointCollectionClasses,
  activeKeypointCollectionClass,
  setActiveKeypointCollectionClass,
  keypointCollections,
  showPhaser,
}) => {
  const phaseLabels = dicomPhases?.map((dicomPhase, index) => {
    const isActive = activeFrameIndex === dicomPhase?.frameIndex
    const keypointCollectionClassValues = keypointCollectionClasses?.map(
      (keypointCollectionClass) => keypointCollectionClass?.value,
    )
    const dicomPhaseKeypointCollections = keypointCollections?.filter(
      (keypointCollection) =>
        keypointCollectionClassValues?.includes(keypointCollection?.keyPointCollectionClass) &&
        keypointCollection?.frame === dicomPhase?.frame,
    )

    const phaseKeypoints = dicomPhaseKeypointCollections?.flatMap((keypointCollection) => keypointCollection?.keyPoints)
    const isLabeled =
      phaseKeypoints?.every((keypoint) => keypoint?.x && keypoint?.y) &&
      dicomPhaseKeypointCollections?.length === keypointCollectionClasses?.length

    const handlePhaseOptionOnClick = () => setActiveFrameIndex(dicomPhase?.frameIndex)

    return (
      <PhaseOption
        key={index}
        dicomPhase={dicomPhase}
        isActive={isActive}
        isLabeled={isLabeled}
        handlePhaseOptionOnClick={handlePhaseOptionOnClick}
      />
    )
  })

  const keypointCollectionClassOptions = useMemo(() => {
    const keypointCollectionHotkeys = ['Q', 'W', 'E', 'R', 'T', 'Y']
    return keypointCollectionClasses?.map((keypointCollectionClass, index) => {
      const isActive = activeKeypointCollectionClass?.uuid === keypointCollectionClass?.uuid
      const keypointCollection = keypointCollections?.find(
        (keypointCollection) =>
          keypointCollection?.keyPointCollectionClass === keypointCollectionClass?.value &&
          keypointCollection?.frame === activeFrame?.uuid,
      )
      const keypoints = keypointCollection?.keyPoints
      const isLabeled = keypoints?.length > 0 && keypoints?.every((keypoint) => keypoint?.x && keypoint?.y)
      return (
        <KeypointCollectionOption
          key={keypointCollectionClass?.uuid}
          hotkey={keypointCollectionHotkeys[index]}
          isActive={isActive}
          isLabeled={isLabeled}
          keypointCollectionClass={keypointCollectionClass}
          setActiveKeypointCollectionClass={setActiveKeypointCollectionClass}
        />
      )
    })
  }, [
    activeFrame?.uuid,
    activeKeypointCollectionClass?.uuid,
    keypointCollectionClasses,
    keypointCollections,
    setActiveKeypointCollectionClass,
  ])

  const visibilityHotkeys = ['J', 'K', 'N', 'M']
  const visibilityOptions = keypointCollectionClasses?.map((keypointCollectionClass, index) => (
    <OptionContainer key={index}>
      <Checkbox checked={true} readOnly />({visibilityHotkeys[index]}) {keypointCollectionClass?.value} is Fully Visible
    </OptionContainer>
  ))

  const keypointClassOptions = useMemo(
    () =>
      activeKeypointCollectionClass?.keyPointClasses?.map((keypointClass, index) => {
        const keypointIndex = keypointClass?.order - 1
        const keypoint = activeKeypoints?.find((keypoint) => keypoint?.keyPointClass === keypointClass?.value)

        const setKeypointIsActive = (isActive) => {
          const newKeypoints = activeKeypoints?.map((keypoint) => ({ ...keypoint, isActive: false }))
          setKeypoints([
            ...newKeypoints?.slice(0, keypointIndex),
            { ...newKeypoints[keypointIndex], isActive },
            ...newKeypoints?.slice(keypointIndex + 1),
          ])
        }

        return (
          <KeypointOption
            key={index}
            keypointClass={keypointClass}
            keypoint={keypoint}
            setKeypointIsActive={setKeypointIsActive}
          />
        )
      }),
    [activeKeypointCollectionClass?.keyPointClasses, activeKeypoints, setKeypoints],
  )

  return (
    <LabelsContainer>
      {showPhaser && (
        <FeatureContainer>
          <FeatureTitle>Phase</FeatureTitle>
          <FeatureOptionsContainer>{phaseLabels}</FeatureOptionsContainer>
        </FeatureContainer>
      )}
      <FeatureContainer>
        <FeatureTitle>Keypoint Collections</FeatureTitle>
        <FeatureOptionsContainer>{keypointCollectionClassOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      {/* <FeatureContainer>
        <FeatureTitle>Visibility</FeatureTitle>
        <FeatureOptionsContainer>{visibilityOptions}</FeatureOptionsContainer>
      </FeatureContainer> */}
      <FeatureContainer>
        <FeatureTitle>Keypoints</FeatureTitle>
        <FeatureOptionsContainer>{keypointClassOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
