import JobChart from './JobChart'
import { CardContent, CardTitle, SummaryCardContainer, TypeTag } from './SummaryCard.style'

const SummaryCard = ({ annotationSet }) => {
  const url =
    annotationSet?.type === 'KEYPOINTS'
      ? `keypoints/${annotationSet?.uuid}`
      : annotationSet?.type === 'SEGMENTATIONS'
        ? `segmentations/${annotationSet?.uuid}`
        : annotationSet?.type === 'FULL_STUDY'
          ? `studies/${annotationSet?.uuid}`
          : '#'

  return (
    <SummaryCardContainer href={url} key={annotationSet?.uuid}>
      <CardTitle>{annotationSet?.name}</CardTitle>
      <TypeTag type={annotationSet?.type}>{annotationSet?.type}</TypeTag>
      <CardContent>
        <JobChart annotationSet={annotationSet} />
      </CardContent>
    </SummaryCardContainer>
  )
}

export default SummaryCard
