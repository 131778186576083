import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (action.payload) {
        localStorage.setItem('user', JSON.stringify(action.payload.user))
        localStorage.setItem('token', action.payload.token)
        state.user = action.payload.user
      } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        state.user = null
      }
    },
  },
})

export const authActionReducer = authSlice.actions
export default authSlice.reducer
