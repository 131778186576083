import { memo } from 'react'

import {
  LabelsContainer,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  TitleContainer,
} from './Labels.style'

import PhaseOption from './PhaseOption'

const Labels = ({ activeFrame, cardiacPhases, dicom, dicomPhases, setDicomPhase }) => {
  const phaseLabels = cardiacPhases?.map((cardiacPhase, index) => {
    const dicomPhase = dicomPhases?.find((dicomPhaseLabel) => dicomPhaseLabel?.phase === cardiacPhase?.value)
    return (
      <PhaseOption
        key={index}
        activeFrame={activeFrame}
        cardiacPhase={cardiacPhase}
        dicom={dicom}
        dicomPhase={dicomPhase}
        setDicomPhase={setDicomPhase}
      />
    )
  })

  return (
    <LabelsContainer>
      <TitleContainer>Set Phases</TitleContainer>
      <FeatureContainer>
        <FeatureTitle>Phase</FeatureTitle>
        <FeatureOptionsContainer>{phaseLabels}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
