import { useHotkeys } from 'react-hotkeys-hook'

import { Checkbox, PhaseOptionContainer } from './PhaseOption.style'

const PhaseOption = ({ hotkey, label, isSelected, setPhase }) => {
  const handlePhaseOptionOnClick = () => {
    setPhase(!isSelected)
  }

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    handlePhaseOptionOnClick()
  })

  return (
    <PhaseOptionContainer isSelected={isSelected} onClick={handlePhaseOptionOnClick}>
      <Checkbox checked={isSelected} readOnly />({hotkey}) {label}
    </PhaseOptionContainer>
  )
}

export default PhaseOption
