import styled from 'styled-components'

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  height: calc(100% - 60px);
  padding: 15px;
  display: flex;
  gap: 15px;
`

export const DatasetsContainer = styled.div.attrs({
  className: 'DatasetsContainer',
})`
  height: 100%;
`

export const TableContainer = styled.div.attrs({
  className: 'TableContainer',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  // background: gray;
`
