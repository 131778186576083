import { useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import {
  StudyCollectionOption,
  StudyCollectionOptionsContainer,
  InputContainer,
  SearchContainer,
  SearchInput,
} from './Search.style'

const Search = ({ studyCollections, setStudyCollection }) => {
  const navigate = useNavigate()

  const containerRef = useRef()

  const [dropdownIsOpen, setDropDownIsOpen] = useState(false)

  const handleInputOnChange = () => {
    /* filter studyCollections */
  }

  const handleInputOnFocus = () => setDropDownIsOpen(true)

  const handleOptionOnClick = (studyCollection) => {
    setDropDownIsOpen(false)
    setStudyCollection(studyCollection)
    navigate(`/collections/${studyCollection?.uuid}`)
  }

  const studyCollectionsOptions = studyCollections?.map((studyCollection) => (
    <StudyCollectionOption key={studyCollection?.uuid} onClick={() => handleOptionOnClick(studyCollection)}>
      {studyCollection?.name}
    </StudyCollectionOption>
  ))

  return (
    <SearchContainer ref={containerRef}>
      <InputContainer>
        <SearchInput
          placeholder="search study collections"
          onChange={handleInputOnChange}
          onFocus={handleInputOnFocus}
        />
        <StudyCollectionOptionsContainer isOpen={dropdownIsOpen}>
          {studyCollectionsOptions}
        </StudyCollectionOptionsContainer>
      </InputContainer>
    </SearchContainer>
  )
}

export default Search
