import { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'

import Loader from './Loader'
import { StyledInput, TableContainer, TableWrapper } from './Table.style'
import './Table.style.css'
import { API_ICARDIO } from '../../../../../constants/env.const'

const Table = ({ dicoms }) => {
  const [search, setSearch] = useState(null)

  const columns = useMemo(
    () => [
      {
        name: 'Study',
        sortable: true,
        cell: (row) => (
          <Link to={`${API_ICARDIO}/almanac?dicom=${row?.uuid}`} className="data-link">
            {row.study}
          </Link>
        ),
      },
      {
        name: 'Index',
        sortable: true,
        cell: (row) => (
          <Link to={`${API_ICARDIO}/almanac?dicom=${row?.uuid}`} className="data-link">
            {row.index}
          </Link>
        ),
      },
      {
        name: 'UUID',
        sortable: true,
        cell: (row) => (
          <Link to={`${API_ICARDIO}/almanac?dicom=${row?.uuid}`} className="data-link">
            {row.uuid}
          </Link>
        ),
      },
      {
        name: 'Type',
        sortable: true,
        cell: (row) => (
          <Link to={`${API_ICARDIO}/almanac?dicom=${row?.uuid}`} className="data-link">
            {row.type}
          </Link>
        ),
      },
    ],
    [],
  )

  const handleSearchOnChange = (e) => setSearch(e.target.value)

  const results = dicoms?.filter(
    (dicom) =>
      !search ||
      dicom?.uuid?.toLowerCase()?.includes(search?.toLowerCase()) ||
      dicom?.study?.toLowerCase()?.includes(search?.toLowerCase()),
  )

  return (
    <TableContainer>
      <TableWrapper>
        <DataTable
          columns={columns}
          data={results}
          pagination
          paginationTotalRows={dicoms?.length}
          paginationDefaultPage={1}
          progressComponent={<Loader />}
          persistTableHead
        />
        <StyledInput placeholder="Search..." value={search || ''} onChange={handleSearchOnChange} />
      </TableWrapper>
    </TableContainer>
  )
}

export default Table
