import styled from 'styled-components'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#222'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    // width: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${disabled ? 'none' : '#444'};;
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const CanvasContainer = styled.div.attrs({
  className: 'CanvasContainer',
})(
  ({ isHidden }) => `  
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: 100%;
    > div { 
      flex-grow: 1;
    }
  `,
)

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  height: calc(100% - 60px);
  padding: 15px;
  display: flex;
  gap: 15px;
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  width: 100%;
  background: #ccc;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
`

export const SideBarContainer = styled.div.attrs({
  className: 'SideBarContainer',
})(
  ({ isHidden }) => `
    height: 100%;
    width: 300px;
    min-width: 300px;
    display: ${isHidden ? 'none' : 'flex'};
    flex-direction: column;
    overflow-y: auto;
    background: gray;
    gap: 10px;
    border-radius: 5px;
    > div {
      // flex-grow: 1;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `,
)

export const StatusTag = styled.div.attrs({
  className: 'StatusTag',
})(
  ({ isHidden, isLabeled, isSkipped }) => `  
    background: ${isLabeled ? 'green' : isSkipped ? 'red' : 'none'};
    color: ${isLabeled || isSkipped ? 'white' : 'none'};
    border-radius: 5px;
    height: 40px;
    width: 100px;
    padding: 0 10px;
    display: ${isHidden ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
  `,
)

export const ViewerContainer = styled.div.attrs({
  className: 'ViewerContainer',
})`
  background: black;
  height: 100%;
  width: 100%;
`
