import { useState, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaCheckCircle } from 'react-icons/fa'
import DataTable from 'react-data-table-component'
import useAxios from 'axios-hooks'

import Loader from './Loader'
import { SEGMENTATION_JOBS } from '../../../../constants/api'
import {
  FactContainer,
  FactTitle,
  IconContainer,
  SideBar,
  StyledInput,
  SubtitleContainer,
  TableContainer,
  TableWrapper,
  TitleContainer,
} from './Table.style'
import './Table.style.css'

const Table = ({ dataset, datasetResponseIsLoading }) => {
  const { datasetId } = useParams()

  const [search, setSearch] = useState(null)

  const columns = useMemo(
    () => [
      {
        name: 'Index',
        selector: (row) => row.order,
        sortable: true,
        cell: (row) => (
          <Link to={`/segmentations/${datasetId}/${row?.uuid}`} className="data-link">
            {row.order}
          </Link>
        ),
      },
      {
        name: 'KeypointJob',
        selector: (row) => row.uuid,
        sortable: true,
        cell: (row) => (
          <Link to={`/segmentations/${datasetId}/${row?.uuid}`} className="data-link">
            {row.uuid}
          </Link>
        ),
      },
      {
        name: 'Dicom',
        selector: (row) => row.dicom,
        sortable: true,
        cell: (row) => (
          <Link to={`/segmentations/${datasetId}/${row?.uuid}`} className="data-link">
            {row.dicom}
          </Link>
        ),
      },
      {
        name: 'Labeled',
        selector: (row) => String(row?.labeled),
        sortable: true,
        cell: (row) => (
          <Link to={`/segmentations/${datasetId}/${row?.uuid}`} className="data-link">
            <IconContainer>{row?.labeled && <FaCheckCircle />}</IconContainer>
          </Link>
        ),
      },
      {
        name: 'Skipped',
        selector: (row) => String(row?.skipped),
        sortable: true,
        cell: (row) => (
          <Link to={`/segmentations/${datasetId}/${row?.uuid}`} className="data-link">
            <IconContainer>{row?.skipped && <FaCheckCircle />}</IconContainer>
          </Link>
        ),
      },
    ],
    [datasetId],
  )

  const params = useMemo(
    () => ({
      annotation_set__uuid: datasetId,
      paginate: false,
    }),
    [datasetId],
  )

  const [{ data, loading }] = useAxios(
    {
      url: SEGMENTATION_JOBS,
      params,
    },
    {
      autoCancel: false,
      useCache: false,
    },
  )

  const isLoading = datasetResponseIsLoading || loading
  const count = useMemo(() => data?.length, [data])
  const results = useMemo(() => {
    if (!search) return data
    return data?.filter(
      (segmentationJob) =>
        segmentationJob?.uuid?.toLowerCase()?.includes(search?.toLowerCase()) ||
        segmentationJob?.dicom?.toLowerCase()?.includes(search?.toLowerCase()),
    )
  }, [data, search])

  const handleSearchOnChange = (e) => setSearch(e.target.value)

  return (
    <TableContainer>
      <SideBar>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TitleContainer>{dataset?.name}</TitleContainer>
            <SubtitleContainer>
              <FactTitle>Jobs Summary</FactTitle>
              <FactContainer>
                <div>Total</div>
                <div>{dataset?.numberOfJobs}</div>
              </FactContainer>
              <FactContainer>
                <div>Labeled</div>
                <div>{dataset?.numberOfLabeledJobs}</div>
              </FactContainer>
              <FactContainer>
                <div>Skipped</div>
                <div>{dataset?.numberOfSkippedJobs}</div>
              </FactContainer>
              <FactContainer>
                <div>Remaining</div>
                <div>{dataset?.numberOfJobs - dataset?.numberOfLabeledJobs - dataset?.numberOfSkippedJobs}</div>
              </FactContainer>
            </SubtitleContainer>
          </>
        )}
      </SideBar>
      <TableWrapper>
        <DataTable
          columns={columns}
          data={results}
          pagination
          paginationTotalRows={count}
          paginationDefaultPage={1}
          progressPending={isLoading}
          progressComponent={<Loader />}
          persistTableHead
        />
        {!isLoading && <StyledInput placeholder="Search..." value={search || ''} onChange={handleSearchOnChange} />}
      </TableWrapper>
    </TableContainer>
  )
}

export default Table
