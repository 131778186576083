import styled from 'styled-components'
import THEME from '../../constants/theme'

export const Card = styled.a.attrs({
  className: 'Card',
})`
  all: unset;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  background-color: gray;
  width: 300px;
  // height: 210px;
  cursor: pointer;
  align-items: space-between;
  gap: 20px;
  padding-bottom: 10px;
  &:hover {
    opacity: 0.9;
  }
`

export const CardsContainer = styled.div.attrs({
  className: 'CardsContainer',
})`
  display: flex;
  gap: 20px;
  padding: 20px 0;
  width: 100%;
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  height: calc(100% - 60px);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
`

export const HomeContainer = styled.div.attrs({
  className: 'HomeContainer',
})`
  height: 100%;
`

export const RowContainer = styled.div.attrs({
  className: 'RowContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
