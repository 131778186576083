import { Route, Routes } from 'react-router-dom'

import StudyCollections from './StudyCollections'
import Auditor from './Auditor'

const Collections = () => (
  <Routes>
    <Route exact path="/" Component={StudyCollections} />
    <Route exact path="/:studyCollectionId" Component={StudyCollections} />
    <Route exact path="/:studyCollectionId/:annotationSetId" Component={Auditor} />
  </Routes>
)

export default Collections
