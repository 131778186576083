export const Calculate = {
  lineLength: function (x1, y1, x2, y2, delta) {
    const pixelDistance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
    const cmDistance = pixelDistance * delta
    return cmDistance
  },
}

export const humanize = (errors, isHideKey = false) =>
  Object.keys(errors).map((k) => `${!isHideKey ? `${k}: ` : ''}${errors[k][0]}`)
