import { memo, useState } from 'react'
import { FrameContainer, StyledImage } from './Frame.style'

const Frame = ({ dicom, frame, isActive, scale }) => {
  return (
    <FrameContainer isActive={isActive}>
      <StyledImage
        src={frame?.file}
        scale={scale}
        style={{
          height: dicom?.pixelHeight,
          width: dicom?.pixelWidth,
        }}
        loading="eager"
      />
    </FrameContainer>
  )
}

export default memo(Frame)
