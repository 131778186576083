import { useHotkeys } from 'react-hotkeys-hook'
import {
  Checkbox,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  UserOptionContainer,
  UsersContainer,
} from './Users.style'

const UserOption = ({ hotkey, user, selectedUsers, setSelectedUsers }) => {
  const isChecked = selectedUsers?.includes(user)

  const handleOnClick = () => {
    if (selectedUsers.includes(user)) setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser !== user))
    else setSelectedUsers([...selectedUsers, user])
  }

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    handleOnClick()
  })

  return (
    <UserOptionContainer key={user} onClick={handleOnClick} isSelected={isChecked}>
      <Checkbox checked={isChecked} />({hotkey}) {user}
    </UserOptionContainer>
  )
}

const Users = ({ users, selectedUsers, setSelectedUsers }) => {
  const userOptions = users?.map((user, index) => (
    <UserOption
      key={user}
      hotkey={`${index + 1}`}
      user={user}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
    />
  ))

  return (
    <UsersContainer>
      <FeatureContainer>
        <FeatureTitle>Users</FeatureTitle>
        <FeatureOptionsContainer>{userOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </UsersContainer>
  )
}

export default Users
