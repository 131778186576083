import { LoadingWrapper } from './Loading.style'

const Loading = () => (
  <LoadingWrapper>
    <svg viewBox="22 22 44 44">
      <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></circle>
    </svg>
  </LoadingWrapper>
)

export default Loading
