import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import Search from './Search'
import Table from './Table'
import { ANNOTATION_SETS } from '../../../constants/api'
import { ContentContainer, DatasetsContainer, TableContainer } from './Datasets.style'

const Datasets = () => {
  const { datasetId } = useParams()
  const [dataset, setDataset] = useState()

  const [{ data: datasetsResponse }] = useAxios({
    url: ANNOTATION_SETS,
    params: { type: 'KEYPOINTS' },
  })

  const [{ loading: datasetResponseIsLoading }, getDataset] = useAxios(
    {
      url: `${ANNOTATION_SETS}${datasetId}/`,
    },
    {
      autoCancel: false,
      manual: true,
    },
  )

  const datasets = datasetsResponse?.results

  useEffect(() => {
    if (!datasetId) return
    getDataset()?.then((response) => setDataset(response?.data))
  }, [datasetId, getDataset])

  return (
    <DatasetsContainer>
      <ContentContainer>
        <TableContainer>
          <Search datasets={datasets} setDataset={setDataset} />
          {datasetId && <Table dataset={dataset} datasetResponseIsLoading={datasetResponseIsLoading} />}
        </TableContainer>
      </ContentContainer>
    </DatasetsContainer>
  )
}

export default Datasets
