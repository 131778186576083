import { Route, Routes } from 'react-router-dom'

import Datasets from './Datasets'
import Viewer from './Viewer'

const Segmentor = () => (
  <Routes>
    <Route exact path="/" Component={Datasets} />
    <Route exact path="/:datasetId" Component={Datasets} />
    <Route exact path="/:datasetId/:jobId" Component={Viewer} />
  </Routes>
)

export default Segmentor
