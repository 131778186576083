import styled from 'styled-components'

export const ScrollerContainer = styled.div.attrs({
  className: 'ScrollerContainer',
})`
  width: 100%;
  // background: white;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  min-height: 32px;
  height: 32px;
`

export const FramePreviewContainer = styled.div.attrs({
  className: 'FramePreviewContainer',
})(
  ({ isActive, isSystolic, isDiastolic }) => `
    background: ${isSystolic ? 'cornflowerblue' : isDiastolic ? 'coral' : '#ccc'};
    opacity: ${isActive ? 1 : 0.7};
    border: 1px solid black;
    border-right: 0;
    flex: 1;
    height: 30px;
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    &: hover {
      opacity: 1;
    }
    &:first-child {
      // border-left: 0;
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
      border-right: 1px solid black;
    }
  `,
)
