import { useEffect, useRef } from 'react'
import { Canvas, FabricText, FabricImage, Line } from 'fabric'

import { Calculate } from '../../../../../utilities'
import { DrawContainer, DrawSection } from './Draw.style'
import { CROSSHAIR_ICON } from '../../../../../constants/images'

const Draw = ({ activeKeypoints, activeFrame, dicom, scale, setKeypoints, showMeasurements }) => {
  const fabricRef = useRef(null)
  const points = activeKeypoints

  const handleDrawSectionOnClick = (event) => {
    const isClickEvent = !event?.transform

    if (!isClickEvent) return

    const _activeKeypoints = window['keypoints']
    const activeKeypointIndex = _activeKeypoints?.findIndex((keypoint) => keypoint?.isActive)
    const noActiveKeyPoints = activeKeypointIndex < 0

    if (noActiveKeyPoints) return

    const previousKeypoints = _activeKeypoints?.slice(0, activeKeypointIndex)
    const nextKeypoint = _activeKeypoints[activeKeypointIndex]

    const newKeypoints = [
      ...previousKeypoints,
      {
        ...nextKeypoint,
        isLabeled: true,
        isActive: false,
        x: event.pointer.x,
        y: event.pointer.y,
      },
      {
        ..._activeKeypoints[activeKeypointIndex + 1],
        isActive: !_activeKeypoints[activeKeypointIndex + 1]?.isLabeled,
      },
      ..._activeKeypoints.slice(activeKeypointIndex + 2),
    ]?.slice(0, _activeKeypoints?.length)

    setKeypoints(newKeypoints)
  }

  const onMovingObj = (evt) => {
    const id = evt.target.id
    const index = id

    var actObj = evt.target
    var coords = actObj.oCoords
    var left = coords.tl.x
    var top = coords.tl.y

    fabricRef.current._objects.forEach((obj) => {
      if (obj.id === index) {
        if (obj.startPointText) {
          obj.startPointText.left = left + 7
          obj.startPointText.top = top + 7
        }
        if (obj.isFirstPoint) {
          obj.startOfLine && obj.startOfLine.set({ x1: left, y1: top })
        } else {
          obj.startOfLine && obj.startOfLine.set({ x1: left, y1: top })
          obj.line && obj.line.set({ x2: left, y2: top })
        }

        if (obj.line) {
          var px = Calculate.lineLength(
            obj.recStart.left,
            obj.recStart.top,
            obj.left,
            obj.top,
            dicom?.physicalDeltaX,
          ).toFixed(2)

          obj.line.text.text = '' + parseFloat(px).toFixed(2) + 'cm'

          obj.line.text.left = (obj.recStart.left + obj.left) / 2 + 20
          obj.line.text.top = (obj.recStart.top + obj.top) / 2 - 15
        }

        if (obj.startOfLine && showMeasurements) {
          var px2 = Calculate.lineLength(
            obj.left,
            obj.top,
            obj.recEnd.left,
            obj.recEnd.top,
            dicom?.physicalDeltaX,
          ).toFixed(2)

          obj.startOfLine.text.text = '' + parseFloat(px2).toFixed(2) + 'cm'

          obj.startOfLine.text.left = (obj.left + obj.recEnd.left) / 2 + 20
          obj.startOfLine.text.top = (obj.top + obj.recEnd.top) / 2 - 15
        }
      }
    })

    fabricRef.current.renderAll()
  }

  const onReleaseObj = (event) => {
    const isDragEvent = !!event?.transform

    if (!isDragEvent) return

    const points = fabricRef?.current?._objects
      ?.filter((point) => point?.id)
      ?.map((point) => ({
        keypointClass: point?.id,
        x: point?.left,
        y: point?.top,
      }))

    const newKeypoints = activeKeypoints?.map((keypoint) => {
      const labeledKeypoint = points?.find((point) => point?.keypointClass === keypoint?.keypointClass?.uuid)
      const x = labeledKeypoint?.x
      const y = labeledKeypoint?.y

      return {
        ...keypoint,
        x,
        y,
      }
    })

    setKeypoints(newKeypoints)
  }

  useEffect(() => {
    if (fabricRef.current && fabricRef.current._objects) {
      fabricRef.current._objects.forEach((obj) => {
        fabricRef.current.remove(obj)
      })
      fabricRef.current.remove(...fabricRef.current.getObjects())
    }
    return () => {
      if (!JSON.stringify(window['keypoints'])) return
      const _arr = JSON.parse(JSON.stringify(window['keypoints']))
      const objs = fabricRef.current.getObjects()
      _arr.map((e) => {
        const _fabricobj = objs.find((c) => c.id === e.id)
        if (_fabricobj) {
          e.x = _fabricobj.left
          e.y = _fabricobj.top
        }
        return e
      })

      window['keypoints'] = _arr
      setKeypoints(_arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFrame, fabricRef, showMeasurements])

  useEffect(() => {
    fabricRef.current = new Canvas('Draw')
    fabricRef.current.setWidth(dicom?.pixelWidth)
    fabricRef.current.setHeight(dicom?.pixelHeight)

    fabricRef.current.on('mouse:down', handleDrawSectionOnClick, false)
    fabricRef.current.on('object:moving', onMovingObj, false)
    fabricRef.current.on('mouse:up', onReleaseObj, false)

    const disposeFabric = () => {
      fabricRef.current.dispose()
    }

    return () => {
      disposeFabric()
    }
  }, [activeFrame, activeKeypoints, showMeasurements])

  useEffect(() => {
    window['keypoints'] = points
  }, [points, activeFrame])

  useEffect(() => {
    if (points) {
      fabricRef.current._objects.forEach((obj) => {
        fabricRef.current.remove(obj)
      })
      fabricRef.current.remove(...fabricRef.current.getObjects())

      const crosshair_icon = document.getElementById('crosshair')

      const p = points.filter((e) => e.x && e.y && e.isLabeled)
      const lines = []
      let recs = []

      if (p.length > 1) {
        for (var index = 0; index < p.length - 1; index++) {
          let i = index

          var startPoint = p[i]
          var endPoint = p[i + 1]

          if (!startPoint) {
            return
          }
          if (startPoint && startPoint.keypointClass) {
            startPoint.id = startPoint.keypointClass.uuid
          }

          if (endPoint && endPoint.keypointClass) {
            endPoint.id = endPoint.keypointClass.uuid
          }

          var rec = new FabricImage(crosshair_icon, {
            radius: 7,
            originX: 'center',
            originY: 'center',
            left: startPoint.x,
            top: startPoint.y,
            fill: 'red',
            id: startPoint.id,
          })

          var startPointText = new FabricText(`${startPoint?.keypointClass?.order}`, {
            left: startPoint.x + 7,
            top: startPoint.y + 7,
            fontSize: 12,
            selectable: false,
            fontWeight: 'bold',
            fill: 'yellow',
          })

          rec.startPointText = startPointText

          fabricRef.current.add(startPointText)
          if (i === 0) {
            rec.hasBorders = false
            rec.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              bl: false,
              br: false,
              tl: false,
              tr: false,
              mtr: false,
            })
          }

          if (endPoint) {
            var rec2 = new FabricImage(crosshair_icon, {
              radius: 7,
              originX: 'center',
              originY: 'center',
              left: endPoint.x,
              top: endPoint.y,
              fill: 'red',
              id: endPoint.id,
            })

            rec2.hasBorders = false
            rec2.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              bl: false,
              br: false,
              tl: false,
              tr: false,
              mtr: false,
            })

            var waterLine = new Line([startPoint['x'], startPoint['y'], endPoint['x'], endPoint['y']], {
              stroke: 'yellow',
              strokeWidth: 2,
              originX: 'center',
              originY: 'center',
              selectable: false,
              strokeDashArray: [10, 5],
            })

            var px = Calculate.lineLength(
              startPoint.x,
              startPoint.y,
              endPoint.x,
              endPoint.y,
              dicom?.physicalDeltaX,
            ).toFixed(2)

            var text = new FabricText('' + parseFloat(px).toFixed(2) + 'cm', {
              left: (startPoint.x + endPoint.x) / 2 + 20,
              top: (startPoint.y + endPoint.y) / 2 - 15,
              fontSize: 12,
              selectable: false,
              fontWeight: 'bold',
              fill: 'yellow',
            })

            const drawLine = startPoint?.keypointClass?.pair === endPoint?.keypointClass?.value

            if (drawLine) {
              waterLine.text = text
              fabricRef.current.add(waterLine)
              lines.push(waterLine)
            }

            if (i === 0) {
              rec.startOfLine = waterLine
              rec.isFirstPoint = true
              rec.recStart = rec
              rec.recEnd = rec2
              recs.push(rec)
              fabricRef.current.add(rec)

              if (rec2) {
                rec2.line = waterLine
                rec2.recStart = rec
                rec2.recEnd = rec2
                recs.push(rec2)
              }
            } else {
              const objectsCanvas = fabricRef.current._objects.filter((obb) => obb.id && obb.id !== '')
              rec2.line = waterLine

              objectsCanvas.map((cc) => {
                if (cc.id === rec.id) {
                  cc.startOfLine = waterLine
                  // cc.recStart = cc
                  cc.recEnd = rec2
                  rec2.recStart = cc
                }
              })

              rec2.recEnd = rec2

              if (i + 1 === p.length - 1) {
                var lastPointText = new FabricText(`${endPoint?.keypointClass?.order}`, {
                  left: endPoint.x + 7,
                  top: endPoint.y + 7,
                  fontSize: 12,
                  selectable: false,
                  fontWeight: 'bold',
                  fill: 'yellow',
                })

                rec2.startPointText = lastPointText
                fabricRef.current.add(lastPointText)
              }

              recs.push(rec2)
            }

            fabricRef.current.add(rec2)
            if (showMeasurements) fabricRef.current.add(text)
          } else if (i === 0) {
            recs.push(rec)
            fabricRef.current.add(rec)
          }
        }
      } else if (p.length === 1) {
        var startPoint = p[0]
        if (startPoint && startPoint.keypointClass) {
          startPoint.id = startPoint.keypointClass.uuid
        }

        var rec = new FabricImage(crosshair_icon, {
          radius: 7,
          originX: 'center',
          originY: 'center',
          left: startPoint.x,
          top: startPoint.y,
          fill: 'red',
          id: startPoint.id,
        })

        rec.hasBorders = false
        rec.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false,
        })

        var startPointText = new FabricText(`${startPoint?.keypointClass?.order}`, {
          left: startPoint.x + 7,
          top: startPoint.y + 7,
          fontSize: 12,
          selectable: false,
          fontWeight: 'bold',
          fill: 'yellow',
        })

        rec.startPointText = startPointText

        fabricRef.current.add(startPointText)

        fabricRef.current.add(rec)
      }
    }
  }, [points, activeFrame, showMeasurements])

  const checkDelete = (evt) => {
    if (evt.keyCode === 46 || evt.key === 'Delete' || evt.code === 'Delete' || evt.key === 'Backspace') {
      if (fabricRef.current.getActiveObject()) {
        if (fabricRef.current.getActiveObject().isEditing) {
          return
        }
        const group = fabricRef.current.getActiveObject()

        const _activeKeypoints = window['keypoints']
        _activeKeypoints.map((e) => {
          if (e.id === group.id) {
            e.x = null
            e.y = null
            e.isLabeled = false
          }
        })
        setKeypoints([..._activeKeypoints])
      }
    }
  }

  return (
    <DrawContainer scale={scale} tabIndex="1000" onKeyUp={(evt) => checkDelete(evt)}>
      <DrawSection />

      <img src={CROSSHAIR_ICON} id="crosshair" width="15px" height="15px" hidden />
    </DrawContainer>
  )
}

export default Draw
