import { useParams, useNavigate, Link } from 'react-router-dom'

import {
  Box,
  BoxContainer,
  BoxesContainer,
  DescriptionContainer,
  DicomCardContainer,
  DicomCardsContainer,
  DicomThumbnail,
  DicomVideoThumbnail,
  EmptyBox,
} from './Dicoms.style'

const Dicoms = ({ dicoms, checkIfDicomIsLabeled, changesAreSaved }) => {
  const { annotationSetId, studyId, dicomId } = useParams()
  const navigate = useNavigate()

  const dicomCards = dicoms?.map((dicom) => {
    const isActive = dicom?.uuid === dicomId
    const dicomLink = `/studies/${annotationSetId}/${studyId}/${dicom?.uuid}`

    const {
      perspectiveIsLabeled,
      dicomPhaseLabelsAreLabeled,
      keypointCollectionsAreLabeled,
      segmentationsAreLabeled,
      dicomPhaseLabelsAreRequired,
      keypointCollectionsAreRequired,
      segmentationsAreRequired,
      dicomIsLabeled,
    } = checkIfDicomIsLabeled(dicom)

    const handleDicomOnClick = (event) => {
      event.preventDefault()
      if (changesAreSaved || window.confirm('You have unsaved changes on this dicom, continue?')) navigate(dicomLink)
    }

    const emptyBox = <EmptyBox>-</EmptyBox>
    const nonRequiredBox = <EmptyBox>x</EmptyBox>

    return (
      <DicomCardContainer
        key={dicom?.index}
        isActive={isActive}
        onClick={handleDicomOnClick}
        isLabeled={dicomIsLabeled}
      >
        <Link to={dicomLink} onClick={(e) => e.preventDefault()}>
          {dicom?.media?.extension === 'mp4' ? (
            <DicomVideoThumbnail src={dicom?.media?.file} alt="thumbnail" />
          ) : (
            <DicomThumbnail src={dicom?.media?.file} alt="thumbnail" />
          )}
          <DescriptionContainer>
            <div>
              [{dicom?.index + 1}] {dicom?.uuid}
            </div>
            <BoxesContainer>
              <BoxContainer>
                {perspectiveIsLabeled ? <Box type="identifier" /> : emptyBox}
                {dicomPhaseLabelsAreLabeled ? (
                  <Box type="phaser" />
                ) : dicomPhaseLabelsAreRequired ? (
                  emptyBox
                ) : (
                  nonRequiredBox
                )}
                {keypointCollectionsAreLabeled ? (
                  <Box type="keypointer" />
                ) : keypointCollectionsAreRequired ? (
                  emptyBox
                ) : (
                  nonRequiredBox
                )}
                {segmentationsAreLabeled ? (
                  <Box type="segmentor" />
                ) : segmentationsAreRequired ? (
                  emptyBox
                ) : (
                  nonRequiredBox
                )}
              </BoxContainer>
              <BoxContainer>{dicomIsLabeled && <Box type="labeled" />}</BoxContainer>
            </BoxesContainer>
          </DescriptionContainer>
        </Link>
      </DicomCardContainer>
    )
  })

  return (
    <div>
      <DicomCardsContainer>{dicomCards}</DicomCardsContainer>
    </div>
  )
}

export default Dicoms
