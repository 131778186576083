import styled from 'styled-components'

export const Checkbox = styled.input.attrs({
  className: 'Checkbox',
  type: 'checkbox',
})`
  margin: 0 2px;
  cursor: pointer;
  accent-color: maroon;
`

export const KeypointOptionContainer = styled.div.attrs({
  className: 'KeypointOptionContainer',
})(
  ({ isLabeled, isActive }) => `
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0.75em 1em;
    cursor: pointer;
    font-size: 12px;
    background: ${isActive ? 'cyan' : isLabeled ? 'lightpink' : 'none'};
    &:hover {
      background: ${isActive ? 'cyan' : isLabeled ? 'pink' : 'none'};
    }
  `,
)
