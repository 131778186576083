import styled from 'styled-components'
import THEME from '../../../../../constants/theme'

export const Checkbox = styled.input.attrs({
  className: 'Checkbox',
  type: 'checkbox',
})(
  ({ isSystolic, isDiastolic, ismidSystole, isPreDiastole }) => `  
    margin: 0 2px;
    cursor: pointer;
    accent-color: ${isSystolic ? THEME.systole : isDiastolic ? THEME.diastole : ismidSystole ? THEME.midSystole : isPreDiastole ? THEME.preDiastole : 'maroon'};
  `,
)

export const LabelsContainer = styled.div.attrs({
  className: 'LabelsContainer',
})`
  background: gray;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 5px;
  gap: 10px;
`

export const FeatureContainer = styled.div.attrs({
  className: 'FeatureContainer',
})`
  background: #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 10px;
`

export const FeatureOption = styled.div.attrs({
  className: 'FeatureOption',
})`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background: lightgray;
  }
`

export const FeatureOptionsContainer = styled.div.attrs({
  className: 'FeatureOptionsContainer',
})`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

export const FeatureTitle = styled.div.attrs({
  className: 'FeatureTitle',
})`
  border-radius: 5px 10px 0 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  &:after {
    content: '';
    height: 5px;
    width: 80%;
    border-bottom: 1px solid gray;
  }
`

export const PhaseOptionContainer = styled.div.attrs({
  className: 'PhaseOptionContainer',
})(
  ({ isSelected }) => `
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0.75em 1em;
    cursor: pointer;
    font-size: 12px;
    background: ${isSelected ? 'lightpink' : 'none'};
    &:hover {
      background: ${isSelected ? 'pink' : 'lightgray'};
    }
  `,
)

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`
