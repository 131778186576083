import { configureStore } from '@reduxjs/toolkit'

import dicomSlice from './dicom/dicomSlice'
import authSlice from './authentication/authSlice'

export const store = configureStore({
  reducer: {
    dicom: dicomSlice,
    auth: authSlice,
  },
})
