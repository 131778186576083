import styled from 'styled-components'

export const EmailContainer = styled.div.attrs({
  className: 'EmailContainer',
})`
  color: #fff;
  font-size: 14px;
  margin-right: 25px;
`

export const HeaderContainer = styled.nav.attrs({
  className: 'HeaderContainer',
})`
  background: #000;
  border-bottom: 1px solid #212b2f;
  display: flex;
  height: 60px;
  padding: 0 20px;
  position: fixed;
  transition: 0.2s;
  width: 100%;
  z-index: 2;
  top: 0;
`

export const HeaderWrapper = styled.div.attrs({
  className: 'HeaderWrapper',
})`
  display: flex;
  margin: 0 auto;
  width: 100%;
`

export const HeaderLogo = styled.div.attrs({
  className: 'HeaderLogo',
})`
  align-items: center;
  display: flex;
  padding-right: 40px;
  img {
    width: 120px;
  }
  &:hover {
    opacity: 0.8;
  }
`

export const HeaderLinks = styled.ul.attrs({
  className: 'HeaderLinks',
})`
  align-items: center;
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const HeaderLinkItem = styled.li.attrs({
  className: 'HeaderLinkItem',
})`
  border-radius: 5px;
  margin-right: 10px;
  padding: 10px;
  transition: 0.2s;
  > a {
    all: unset;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    cursor: pointer;
    &: hover {
      opacity: 0.8;
    }
  }
`

export const HeaderControls = styled.div.attrs({
  className: 'HeaderControls',
})`
  margin-left: auto;
  display: flex;
  align-items: center;
  & button {
    min-width: 125px;
    img {
      margin-right: 5px;
    }
  }
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  position: absolute;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  > svg {
    height: 100%;
    fill: white;
    font-size: 25px;
  }
`
