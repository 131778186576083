import { TitleContainer } from '../Actions/Actions.style'
import Controls from './Controls'
import Labels from './Labels'

const Keypointer = ({
  activeFrame,
  activeFrameIndex,
  activeKeypoints,
  setActiveFrameIndex,
  dicomPhases,
  keypointCollectionClasses,
  activeKeypointCollectionClass,
  setActiveKeypointCollectionClass,
  showMeasurements,
  showPhaser,
  setShowMeasurements,
  keypointCollections,
  setKeypoints,
}) => {
  return (
    <div>
      <TitleContainer>Label Keypoints</TitleContainer>
      <Labels
        setKeypoints={setKeypoints}
        activeKeypoints={activeKeypoints}
        activeFrameIndex={activeFrameIndex}
        activeFrame={activeFrame}
        setActiveFrameIndex={setActiveFrameIndex}
        dicomPhases={dicomPhases}
        keypointCollectionClasses={keypointCollectionClasses}
        activeKeypointCollectionClass={activeKeypointCollectionClass}
        setActiveKeypointCollectionClass={setActiveKeypointCollectionClass}
        keypointCollections={keypointCollections}
        showPhaser={showPhaser}
      />
      <Controls showMeasurements={showMeasurements} setShowMeasurements={setShowMeasurements} />
    </div>
  )
}

export default Keypointer
