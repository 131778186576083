import styled from 'styled-components'

export const LoginContainer = styled.div.attrs({})`
  min-height: 100vh;
  background-color: #000;
`

export const HeaderLoginContainer = styled.nav.attrs({
  className: 'HeaderLoginContainer',
})`
  background: #000;
  border-bottom: 1px solid #212b2f;
  display: flex;
  height: 64px;
  padding: 0 20px;
  position: fixed;
  transition: 0.2s;
  width: 100%;
  z-index: 2;
`

export const HeaderLoginWrapper = styled.div.attrs({
  className: 'HeaderLoginWrapper',
})`
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`

export const HeaderLoginLogo = styled.div.attrs({
  className: 'HeaderLoginLogo',
})`
  align-items: center;
  display: flex;
  padding-right: 40px;
  img {
    width: 120px;
  }
`

export const HeaderLoginLinksWrapper = styled.div.attrs({
  className: 'HeaderLoginLinksWrapper',
})`
  width: 100%;
`

export const HeaderLoginLinks = styled.ul.attrs({
  className: 'HeaderLoginLinks',
})`
  align-items: center;
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const HeaderLoginLinkItem = styled.li.attrs({
  className: 'HeaderLoginLinkItem',
})`
  border-radius: 5px;
  margin-right: 10px;
  padding: 10px;
  transition: 0.2s;
`

export const HeaderLoginLink = styled.a.attrs({
  className: 'HeaderLoginLink',
})`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
`

export const HeaderLoginControls = styled.div.attrs({
  className: 'HeaderLoginControls',
})`
  width: 400px;
  margin-left: auto;
  display: flex;
  align-items: center;
  & button {
    min-width: 125px;
    img {
      margin-right: 5px;
    }
  }
`

export const ContainerBodyWrapper = styled.div.attrs({
  className: 'ContainerBodyWrapper',
})`
  padding-bottom: 150px;
  padding-top: 150px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginForm = styled.form.attrs({
  className: 'LoginForm',
})`
  background-color: #212b2fcc;
  border-radius: 8px;
  border-top: 1px solid #32444d;
  margin: 0 auto;
  padding: 20px;
  width: 400px;
  h2 {
    margin-bottom: 40px;
    color: #fff;
  }
`

export const LoginFormControl = styled.div.attrs({
  className: 'LoginFormControl',
})`
  display: block;
  margin-bottom: 15px;
  text-align: center;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  margin-bottom: 15px;
`

export const LoginFormControlLabel = styled.div.attrs({
  className: 'LoginFormControlLabel',
})`
  color: #ffffffb3;
  font-size: 12px;
  margin-bottom: 5px;
  padding-left: 5px;
  text-align: left;
`

export const LoginFormControlItem = styled.div.attrs({
  className: 'LoginFormControlItem',
})`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  width: 100%;
`

export const FooterContainer = styled.footer.attrs({
  className: 'FooterContainer',
})`
  background-color: #192023;
  border-top: 1px solid #32444d;
  padding-top: 100px;
  width: 100%;
  -webkit-box-flex: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`
export const FooterWrapper = styled.div.attrs({
  className: 'FooterWrapper',
})`
  margin: 0 auto 100px;
  width: 100%;
  max-width: 83.3333%;
  img {
    width: 150px;
  }
`

export const FooterHeader = styled.div.attrs({
  className: 'FooterHeader',
})`
  margin-bottom: 50px;
`

export const FooterSlogan = styled.div.attrs({
  className: 'FooterSlogan',
})`
  color: #99b2bf;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 35px;
  span {
    -webkit-text-fill-color: #0000;
    -webkit-background-clip: text;
    background-image: linear-gradient(270deg, #01ffff, #09a4ed);
    font-weight: 700;
  }
`

export const FooterLine = styled.div.attrs({
  className: 'FooterLine',
})`
  background-image: linear-gradient(270deg, #01ffff, #09a4ed);
  height: 2px;
  width: 100%;
`

export const FooterWrapFields = styled.div.attrs({
  className: 'FooterWrapFields',
})`
  border-bottom: 1px solid #32444d;
  margin-bottom: 10px;
  padding-bottom: 40px;
  display: flex;
`

export const FooterWrapListLink = styled.div.attrs({
  className: 'FooterWrapListLink',
})`
  padding-right: 40px;
  width: 100%;
`

export const FooterWrapRows = styled.div`
  display: flex;
`

export const FooterWrapLinkHeader = styled.div.attrs({
  className: 'FooterWrapLinkHeader',
})`
  border-bottom: 1px solid #32444d;
  color: #fff;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-left: 10px;
  width: 100%;
`

export const FooterWrapList = styled.ul.attrs({
  className: 'FooterWrapList',
})`
  padding: 0;
  width: 100%;
`

export const FooterWrapListLI = styled.li.attrs({
  className: 'FooterWrapListLI',
})`
  align-items: center;
  border-radius: 5px;
  color: #99b2bf;
  display: flex;
  font-weight: 400;
  list-style: none;
  transition: 0.2s;
  margin: 0;
  padding: 0;
`

export const FooterWrapForm = styled.div.attrs({
  className: 'FooterWrapForm',
})`
  width: 435px;
  min-width: 435px;
  background-image: linear-gradient(220deg, #00d4d4, #0f87ff);
  border-radius: 5px;
  box-shadow:
    -8px 0 32px 0 #1698e933,
    8px 0 32px 0 #07c8a433;
  color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  h3 {
    margin: 0 0 15px;
  }
  p {
    color: #f6f9fabf;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
  }
`

export const FooterCopyRight = styled.div.attrs({
  className: 'FooterCopyRight',
})`
  color: #7e929e;
  font-size: 14px;
  font-weight: 600;
`

export const FooterWrapItems = styled.div.attrs({
  className: 'FooterWrapItems',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
`

export const ButtonContact = styled.button`
  background: #fff;
  color: #09a4ed;
  font-weight: 700;
  height: 45px;
  letter-spacing: 1px;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
`

export const FooterContainerContact = styled.div.attrs({
  className: 'FooterContainerContact',
})``

export const FlexActions = styled.div.attrs({
  className: 'FlexActions',
})`
  display: flex;
  width: 100%;
  color: #fff;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 150px;
  }
  img {
    width: 24px;
    margin-right: 5px;
  }
`
