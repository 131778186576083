import styled from 'styled-components'
import THEME from '../../../../constants/theme'

export const IdentifierContainer = styled.div.attrs({
  className: 'IdentifierContainer',
})`
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled, isActive }) => `
    background: ${isActive ? THEME?.identifier : disabled ? 'none' : THEME?.identifierLight};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    width: 127px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${isActive ? THEME.identifier : disabled ? 'none' : '#444'};;
    }
    font-size: 12px;
    padding: 5px;
  `,
)

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`

export const QualityButton = styled.div.attrs({
  className: 'QualityButton',
})(
  ({ disabled, isActive, type }) => `
    background: ${type === 'good' ? 'green' : 'red'};
    opacity: ${isActive ? 1.0 : 0.2};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    width: 127px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      opacity: ${isActive ? 1.0 : 0.6};
    }
    font-size: 12px;
    padding: 5px;
  `,
)

export const Title = styled.div.attrs({
  className: 'Title',
})`
  border-radius: 5px 10px 0 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  &:after {
    content: '';
    height: 5px;
    width: 80%;
    border-bottom: 1px solid gray;
  }
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`

export const ViewsContainer = styled.div.attrs({
  className: 'ViewsContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ccc;
  // margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
`

export const WindowsContainer = styled.div.attrs({
  className: 'WindowsContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  // background: #ccc;
  // margin-bottom: 20px;
  border-radius: 5px;
  // padding: 5px;
  gap: 10px;
`
