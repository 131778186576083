import axios from 'axios'
import { BrowserRouter } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ICardio from './components'




// request interceptor to add token to request headers
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
   

    if (token) {
      config.headers = { Authorization: 'Token ' + token }
    } else {
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

function App() {
  return (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
      <BrowserRouter basename={'/'}>
        <ICardio />
        <ToastContainer position="top-right" theme="colored"/>
      </BrowserRouter>
    </StyleSheetManager>
  )
}

export default App
