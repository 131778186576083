import styled from 'styled-components'

export const FactContainer = styled.div.attrs({
  className: 'FactContainer',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SummaryContainer = styled.div.attrs({
  className: 'SummaryContainer',
})`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  gap: 3px;
  border-radius: 5px;
  margin: 10px;
  min-height: 60px;
  justify-content: space-between;
`
