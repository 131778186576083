import styled from 'styled-components'

export const QualifierContainer = styled.div.attrs({
  className: 'QualifierContainer',
})`
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: gray;
`

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`

export const QualityButton = styled.div.attrs({
  className: 'QualityButton',
})(
  ({ disabled, isActive, type }) => `
    background: ${type === 'good' ? 'green' : 'red'};
    opacity: ${isActive ? 1.0 : 0.2};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    width: 127px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      opacity: ${isActive ? 1.0 : 0.6};
    }
    font-size: 12px;
    padding: 5px;
  `,
)

export const Title = styled.div.attrs({
  className: 'Title',
})`
  border-radius: 5px 10px 0 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  &:after {
    content: '';
    height: 5px;
    width: 80%;
    border-bottom: 1px solid gray;
  }
`

export const ViewsContainer = styled.div.attrs({
  className: 'ViewsContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ccc;
  // margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
`
