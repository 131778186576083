import { memo, useState } from 'react'
import { FrameContainer, StyledImage } from './Frame.style'
import ReactLassoSelect, { getCanvas } from 'react-lasso-select'

const Frame = ({ dicom, frame, isActive, scale, action, setProcessing, setClippedImg }) => {
  const [image, setImage] = useState(frame?.file)
  const [pointsLasso, setPoints] = useState([])

  return (
    <FrameContainer isActive={isActive}>
      {/* {action === 'crop' && (
        <ReactLassoSelect
          style={{
            position: 'absolute',
            zIndex: 9,
          }}
          imageStyle={{
            transform: `scale(${scale})`,
            height: dicom?.pixelHeight,
            width: dicom?.pixelWidth,
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
          value={pointsLasso}
          src={image}
          onChange={(value) => {
            setPoints(value)
          }}
          onComplete={(value) => {
            if (!value.length) return
            setProcessing(true)
            getCanvas(frame?.file, value, (err, canvas) => {
              if (!err) {   
                setImage(canvas.toDataURL())
                setClippedImg(canvas.toDataURL())
                setProcessing(false)
              }
            })
            setPoints([])
          }}
        />
      )} */}
      <StyledImage
        src={image}
        scale={scale}
        style={{
          height: dicom?.pixelHeight,
          width: dicom?.pixelWidth,
        }}
        loading="eager"
      />
    </FrameContainer>
  )
}

export default memo(Frame)
