import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'

import {
  ButtonsContainer,
  QualifierContainer,
  QualityButton,
  Title,
  ViewsContainer,
  TitleContainer,
} from './Qualifier.style'

const Qualifier = ({ dicom, perspective, setPerspective }) => {
  const goodQualityIsActive = perspective?.quantifiability === 'good'
  const badQualityIsActive = perspective?.quantifiability === 'bad'

  const handleQualityOnClick = (type) => {
    setPerspective({
      ...perspective,
      dicom: dicom?.uuid,
      quantifiability: type,
    })
  }

  return (
    <QualifierContainer>
      <TitleContainer>Set Quality</TitleContainer>
      <ViewsContainer>
        <Title>Image Quality</Title>
        <ButtonsContainer>
          <QualityButton type="good" isActive={goodQualityIsActive} onClick={() => handleQualityOnClick('good')}>
            <FaThumbsUp />
          </QualityButton>
          <QualityButton type="bad" isActive={badQualityIsActive} onClick={() => handleQualityOnClick('bad')}>
            <FaThumbsDown />
          </QualityButton>
        </ButtonsContainer>
      </ViewsContainer>
    </QualifierContainer>
  )
}

export default Qualifier
