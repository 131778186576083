import { ContentContainer, DetailsContainer, FactContainter, FactLabel } from './Details.style'

const Details = ({ dataset, systolicFrame, diastolicFrame }) => {
  const hasSystolicFrame = typeof systolicFrame?.index === 'number'
  const hasDiastolicFrame = typeof diastolicFrame?.index === 'number'
  const systolicFrameIndex = hasSystolicFrame ? systolicFrame?.index + 1 : 'not set'
  const diastolicFrameIndex = hasDiastolicFrame ? diastolicFrame?.index + 1 : 'not set'

  const featuresCount = dataset?.features?.length
  const systolicSegmentationsCount =
    systolicFrame?.segmentations?.filter((segmentation) => !!segmentation?.paths?.length)?.length || 0
  const diastolicSegmentationsCount =
    diastolicFrame?.segmentations?.filter((segmentation) => !!segmentation?.paths?.length)?.length || 0
  const systolicFrameIsLabeled = systolicSegmentationsCount === featuresCount
  const diastolicFrameIsLabeled = diastolicSegmentationsCount === featuresCount

  return (
    <DetailsContainer>
      <ContentContainer>
        <FactContainter>
          <div>Systolic Frame: </div>
          <FactLabel isSystolic isLabeled={hasSystolicFrame}>
            {systolicFrameIndex}
          </FactLabel>
        </FactContainter>
        <FactContainter>
          <div>Diastolic Frame: </div>
          <FactLabel isDiastolic isLabeled={hasDiastolicFrame}>
            {diastolicFrameIndex}
          </FactLabel>
        </FactContainter>
        <FactContainter>
          <div>Systolic Segmentation: </div>
          <FactLabel isSystolic isLabeled={systolicFrameIsLabeled}>
            {`${systolicSegmentationsCount}/${featuresCount}`}
          </FactLabel>
        </FactContainter>
        <FactContainter>
          <div>Diastolic Segmentation: </div>
          <FactLabel isDiastolic isLabeled={diastolicFrameIsLabeled}>
            {`${diastolicSegmentationsCount}/${featuresCount}`}
          </FactLabel>
        </FactContainter>
      </ContentContainer>
    </DetailsContainer>
  )
}

export default Details
