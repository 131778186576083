import Labels from './Labels'

const Phaser = ({ activeFrame, cardiacPhases, dicom, dicomPhases, setDicomPhase }) => {
  return (
    <div>
      <Labels
        activeFrame={activeFrame}
        cardiacPhases={cardiacPhases}
        dicom={dicom}
        dicomPhases={dicomPhases}
        setDicomPhase={setDicomPhase}
      />
    </div>
  )
}

export default Phaser
