import styled from 'styled-components'

export const DatasetOption = styled.div.attrs({
  className: 'DatasetOption',
})`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 0.5em;
  align-items: center;
  // gap: 1em;
  cursor: pointer;
  background: white;
  padding: 3px 1em;
  &:hover {
    background: lightblue;
  }
  > span {
    width: auto;
    text-align: right;
  }
`

export const InputContainer = styled.div.attrs({
  className: 'InputContainer',
})`
  position: relative;
  width: 100%;
  // padding: 0 20px;
  margin: auto;
  margin-top: 0;
`

export const SearchContainer = styled.div.attrs({
  className: 'SearchContainer',
})`
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const SearchInput = styled.input.attrs({
  className: 'SearchInput',
})`
  border: none;
  outline: none;
  box-shadow: none;
  background: white;
  width: 100%;
  height: 40px;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid rgb(230, 230, 230);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
`

export const DatasetOptionsContainer = styled.div.attrs({
  className: 'DatasetOptionsContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    width: 100%;
    top: 36px;
    position: absolute;
    background: white;
    border: 1px solid rgb(230, 230, 230);
    border-top: none;
    border-radius: 0 0 10px 10px;
    z-index: 185;
    overflow-y: auto;
    padding-bottom: 10px;
  `,
)
