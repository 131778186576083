import Toggle from 'react-toggle'

import { ControlsContainer, TitleContainer, ToggleContainer, ToggleSectionContainer } from './Controls.style'
import './Controls.style.css'

const Controls = ({ nextOnSave, showMeasurements, setNextOnSave, setShowMeasurements }) => {
  const handleToggleNextOnSave = () => setNextOnSave((nextOnSave) => !nextOnSave)
  const handleToggleShowMeasurements = () => setShowMeasurements((showMeasurements) => !showMeasurements)

  return (
    <ControlsContainer>
      {/* <TitleContainer>Controls</TitleContainer> */}
      <ToggleSectionContainer>
        <ToggleContainer onClick={handleToggleNextOnSave}>
          <div>Next on Save</div>
          <Toggle checked={nextOnSave} onClick={handleToggleNextOnSave} readOnly />
        </ToggleContainer>
        <ToggleContainer onClick={handleToggleShowMeasurements}>
          <div>Show Measurements</div>
          <Toggle checked={showMeasurements} onClick={handleToggleShowMeasurements} readOnly />
        </ToggleContainer>
      </ToggleSectionContainer>
    </ControlsContainer>
  )
}

export default Controls
