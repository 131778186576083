import { SlControlEnd, SlControlPlay, SlControlPause, SlControlStart, SlControlForward } from 'react-icons/sl'
import { useHotkeys } from 'react-hotkeys-hook'

import { Button, ControlsContainer, HotkeyContainer } from './Controls.style'

const Controls = ({ setActiveFrame, numberOfFrames, playVideo, pauseVideo, videoState }) => {
  const handlePreviousOnClick = () => {
    pauseVideo()
    setActiveFrame((activeFrame) => {
      if (activeFrame === 0) return numberOfFrames - 1
      return activeFrame - 1
    })
  }

  const handleForwardOnClick = async () => {
    await pauseVideo()
    if (videoState === 4) return
    playVideo(4)
  }

  const handlePlayOnClick = async () => {
    await pauseVideo()
    if (videoState === 1) return
    playVideo(1)
  }

  const handleNextOnClick = () => {
    pauseVideo()
    setActiveFrame((activeFrame) => {
      if (activeFrame === numberOfFrames - 1) return 0
      return activeFrame + 1
    })
  }

  useHotkeys('z', (event) => {
    event?.preventDefault()
    handlePreviousOnClick()
  })

  useHotkeys('x', (event) => {
    event?.preventDefault()
    handleForwardOnClick()
  })

  useHotkeys('c', (event) => {
    event?.preventDefault()
    handlePlayOnClick()
  })

  useHotkeys('v', (event) => {
    event?.preventDefault()
    handleNextOnClick()
  })

  return (
    <ControlsContainer>
      <Button onClick={handlePreviousOnClick}>
        <HotkeyContainer>(Z)</HotkeyContainer>
        <SlControlStart />
      </Button>
      <Button onClick={handleForwardOnClick}>
        <HotkeyContainer>(X) 1/4</HotkeyContainer>
        {videoState === 4 ? <SlControlPause /> : <SlControlPlay />}
      </Button>
      <Button onClick={handlePlayOnClick}>
        <HotkeyContainer>(C)</HotkeyContainer> {videoState === 1 ? <SlControlPause /> : <SlControlPlay />}
      </Button>
      <Button onClick={handleNextOnClick}>
        <HotkeyContainer>(V)</HotkeyContainer>
        <SlControlEnd />
      </Button>
    </ControlsContainer>
  )
}

export default Controls
