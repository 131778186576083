import { Route, Routes } from 'react-router-dom'

import AnnotationSets from './AnnotationSets'
import Viewer from './Viewer'

const Studies = () => (
  <Routes>
    <Route exact path="/" Component={AnnotationSets} />
    <Route exact path="/:annotationSetId" Component={AnnotationSets} />
    <Route exact path="/:annotationSetId/:studyId" Component={Viewer} />
    <Route exact path="/:annotationSetId/:studyId/:dicomId" Component={Viewer} />
  </Routes>
)

export default Studies
