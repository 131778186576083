import styled from 'styled-components'

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`
export const DetailsContainer = styled.div.attrs({
  className: 'DetailsContainer',
})`
  background: gray;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 5px;
`

export const FactContainter = styled.div.attrs({
  className: 'FactContainer',
})`
  display: flex;
  justify-content: space-between;
`

export const FactLabel = styled.div.attrs({
  className: 'FactLabel',
})(
  ({ isSystolic, isDiastolic, isLabeled }) => `
    background: ${!isLabeled ? 'none' : isSystolic ? 'cornflowerblue' : isDiastolic ? 'coral' : 'none'};
    min-width: 75px;
    border-radius: 3px;   
    display: flex;
    justify-content: center;
    padding: 3px 5px;
  `,
)

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`
