import { ButtonWrapper, ButtonStyled } from './Button.style'
import Loading from '../Loading'

const Button = ({ label, buttonType, type = 'button', fullwidth = false, loading, ...props }) => (
  <ButtonWrapper className={buttonType} fullwidth={fullwidth} {...props} loading={loading}>
    <ButtonStyled type={type} fullwidth={fullwidth}>
      {loading ? <Loading /> : label}
    </ButtonStyled>
  </ButtonWrapper>
)

export default Button
