import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'
import useAxios from 'axios-hooks'

import { ANNOTATION_SETS, DICOMS, KEYPOINT_JOBS } from '../../../constants/api'
import Loader from './Loader'
import JobChart from './JobChart'
import Users from './Users'
import {
  AuditorContainer,
  Button,
  ColumnContainer,
  ColumnTitle,
  ContentContainer,
  FactContainer,
  FactTitle,
  HeaderContainer,
  SideBarContainer,
  SubtitleContainer,
  Title,
  TitleContainer,
  WidgetContainer,
} from './Auditor.style'
import Dicoms from './Dicoms'

const Auditor = () => {
  const { studyCollectionId, annotationSetId } = useParams()

  const [selectedUsers, setSelectedUsers] = useState([])

  const keypointJobsParams = useMemo(
    () => ({
      annotation_set__uuid: annotationSetId,
      paginate: false,
    }),
    [annotationSetId],
  )
  const dicomsParams = useMemo(
    () => ({
      annotation_sets__uuid: annotationSetId,
      paginate: false,
    }),
    [annotationSetId],
  )

  const [{ data: annotationSet, loading: annotationSetResponseIsLoading }] = useAxios(
    `${ANNOTATION_SETS}${annotationSetId}/`,
  )
  const [{ data: keypointJobs, loading: keypointJobsResponseIsLoading }] = useAxios({
    url: KEYPOINT_JOBS,
    params: keypointJobsParams,
  })
  const [{ data: dicoms, loading: dicomsResponseIsLoading }] = useAxios({
    url: `${DICOMS}`,
    params: dicomsParams,
  })

  const isLoading = annotationSetResponseIsLoading || keypointJobsResponseIsLoading || dicomsResponseIsLoading

  useEffect(() => {
    if (!annotationSet) return
    setSelectedUsers(annotationSet?.users)
  }, [annotationSet])

  return (
    <AuditorContainer>
      <HeaderContainer>
        <Button>
          <a href={`/collections/${studyCollectionId}`}>
            <IoMdArrowRoundBack />
            <span>BACK TO STUDY COLLECTION</span>
          </a>
        </Button>
      </HeaderContainer>
      <ContentContainer>
        <SideBarContainer>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <TitleContainer>
                <Title>{annotationSet?.name}</Title>
                <SubtitleContainer>
                  <FactTitle>Details</FactTitle>
                  <FactContainer>
                    <div>Number of Users:</div>
                    <div>{annotationSet?.users?.length}</div>
                  </FactContainer>
                  <FactContainer>
                    <div>Number of Dicoms:</div>
                    <div>{dicoms?.length}</div>
                  </FactContainer>
                  <FactContainer>
                    <div>Number of Jobs:</div>
                    <div>{keypointJobs?.length}</div>
                  </FactContainer>
                </SubtitleContainer>
              </TitleContainer>
              <Users users={annotationSet?.users} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
            </>
          )}
        </SideBarContainer>
        <WidgetContainer>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <ColumnContainer>
                <ColumnTitle>Jobs</ColumnTitle>
                <JobChart keypointJobs={keypointJobs} selectedUsers={selectedUsers} />
              </ColumnContainer>
              <ColumnContainer style={{ maxHeight: '100%', height: '100%' }}>
                <ColumnTitle>Dicoms</ColumnTitle>
                <Dicoms dicoms={dicoms} />
              </ColumnContainer>
            </>
          )}
        </WidgetContainer>
      </ContentContainer>
    </AuditorContainer>
  )
}

export default Auditor
